import React, { Component } from 'react';
import Wallpaper from '../component/common/ef-wallper';
import MainComponent from '../component/setting/comp-setting-task';
import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "../component/common/redux-dispatch-to-props";

class ConSettingTask extends Component {
    constructor(props){
        super(props)
    }
    render() {

        return (
            <Wallpaper {...this.props} hasHeader={true} hasFooter={true} >
                <MainComponent {...this.props}/>

            </Wallpaper>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConSettingTask)