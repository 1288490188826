import React, {Component} from 'react';
import MultipleDatePicker from 'react-multiple-datepicker';
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';

import {connect} from 'react-redux'
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';
import MonthActivityCalendar from "../common/comp-calendar";
import "../../asset/css/attendance.css";
import FlatButton from "material-ui/FlatButton";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Dialog from "material-ui/Dialog";

class LeaveApplicationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organisation:"",
            Categories: [],
            PendingLeaves: [],
            PeriodTypes: [
                {name: "day", desc: "Days"},
                {name: "hour", desc: "Hours"},
            ],
            ResponseMethods: [
                {name: "email", desc: "Email"},
                {name: "sms", desc: "Sms"},
                {name: "system", desc: "System"},
            ],
            startDate: new Date(),
            endDate: new Date(),
            category: "",
            comment: "",
            periodType: "day",
            responseMethod: "email",
            periodValue: "",
            managerEmail: "",
            managerName: "",
            applicantEmail: "",
            applicantPhone: "",
            applicantDepartment: "",
            //Popup
            showLeaveDetail:false,
            selectedLeave:null
        }

    }

    componentWillMount() {
        const user = GetUserToken();
        this.setState({organisation: user.OrgCode});
    }

    async componentDidMount() {
        await this.fetchLeaveCategory();
        await this.findPendingLeave();
    }

    findPendingLeave = async () => {
        const state = this.state;
        let user = GetUserToken();
        let hub = {
            Org: state.organisation,
        };
        console.log("Submit findAllLeave send > ", hub);
        let backend = eConfig.mainBackend;
        let endpoint = "/api/leave/list/pending";
        const _this = this;
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("Submit findAllLeave response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                _this.setState({
                    PendingLeaves: dataIn.RESULT
                });
            }
        });
    }
    fetchLeaveCategory = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: this.state.organisation,
            Type: "string"
        }];
        await this.fetchingAll("leaveCategory", conds, (data) => {
            _this.setState({
                Categories: data,
            });
        });
    }
    fetchingAll = async (table, conds, callback) => {
        let hub = {
            Fields: [],
            DbName: "timeattendance",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";
        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }
    selectionBox = (label, sourceList, sourceField, sourceLabel, stateKey, handleFunc, conds, required) => {
        const state = this.state;

        let ls = [];
        let data = [];
        if (typeof this.state[sourceList] !== "undefined") {
            data = this.state[sourceList];
        }

        for (let i in data) {
            const row = data[i];
            const field = row[sourceField];
            const desc = row[sourceLabel];

            let boo = true;
            for (let a in conds) {
                const checkEntyKey = conds[a].entry;
                const checkStateKey = conds[a].state;
                const checkEntryVal = row[checkEntyKey];
                const checkStateVal = state[checkStateKey];
                if (checkEntryVal !== checkStateVal) {
                    boo = false;
                }

            }

            if (boo) {
                ls.push({
                    field: field,
                    desc: desc
                })
            }
        }

        const _this = this;
        const setValue = (e) => {
            let tmp = _this.state;
            tmp[stateKey] = e.target.value;
            _this.setState(tmp)
        }
        const getValue = () => {
            let tmp = _this.state;
            return tmp[stateKey];
        }

        return (
            <div className="form-row">
                <div className="form-group col-md-16">
                    <label htmlFor="inputState">{label}</label>
                    <select
                        id="inputState"
                        className="form-control"
                        onChange={(e) => setValue(e)}
                        value={getValue()}
                        required={required}
                    >
                        <option value="" selected>Choose...</option>
                        {ls.map((row, index) => {
                            return (
                                <option key={index} value={row.field}>{row.desc}</option>
                            )
                        })}

                    </select>
                </div>
            </div>
        )
    }
    handleInput = (e, key) => {
        let tmp = this.state;
        tmp[key] = e.target.value;
        this.setState(tmp);
    }
    getValue = (key) => {
        if (typeof this.state[key] !== "undefined") {
            return this.state[key]
        }
        return "";
    }

    handleChangeStartDate = (date) => {
        this.setState({
            startDate: date
        });
    }
    handleChangeEndDate = (date) => {
        this.setState({
            endDate: date
        });
    }

    viewLeave=(row)=>{
        this.setState({
            showLeaveDetail:true,
            selectedLeave:row
        })
    }
    closeViewLeave=()=>{
        this.setState({
            showLeaveDetail:false,
            selectedLeave:null
        })
    }

    submitDecision=async (decision)=>{
        let _this = this;
        let state = this.state;
        let user = GetUserToken();
        if(state.comment ===""){
            return alert("Please Provide your decision Comment!");
        }

        if (!window.confirm("Your sure that you want to submit this APPLICATION?")) {
            return
        }



        let hub = {};
        hub.Org = state.organisation;;
        hub.Ref =state.selectedLeave.Ref;
        hub.Decision = decision;
        hub.Username = user.Username;
        hub.Fullname =  user.Fullname;
        hub.Note = state.comment;


        console.log("Submit submitDecision send > ", hub);
        let backend = eConfig.mainBackend;
        let endpoint = "/api/leave/qa/new";
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("Submit submitDecision response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you, Decision submitted");
                _this.closeViewLeave();
                _this.findPendingLeave();
            }
        });
    }

    renderLeaveDetail=()=>{
        if(this.state.selectedLeave ===null){
            return null
        }



        const actions = [
            <FlatButton
                label="Cancel"
                onClick={this.closeViewLeave}
            />,
            <FlatButton
                label="Approve"
                primary={true}
                onClick={()=>this.submitDecision("approve")}
            />,
            <FlatButton
                label="Reject"
                secondary={true}
                onClick={()=>this.submitDecision("reject")}
            />,
        ];

        let ls=[];
        const avoidedList=(key)=>{
            const ls = ["Id","Status","Profile","Manager","OrgDateTime","CostWorker","CostCompany","CostClient"];
            for(let i in ls){

                //console.log(":::> ",i," = ",key," > ",(i ===key));
                if(ls[i] ===key){
                    console.log(":::> ",key);
                    return false
                }
            }

            return true
        }


        for(let i in this.state.selectedLeave){

            //console.log(":::> ",i);
            const val = this.state.selectedLeave[i];
            if(avoidedList(i)){
                ls.push({
                    key:i,
                    val:val
                })
            }
        }
        return (
            <MuiThemeProvider>
                <Dialog
                    title={"Leave  Detail"}
                    actions={actions}
                    modal={false}
                    open={this.state.showLeaveDetail}
                    onRequestClose={this.closeShowDetail}
                    autoScrollBodyContent={true}
                >

                    <br/>

                    <div className="col-lg-12">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Key</th>
                                <th>Value</th>
                            </tr>
                            </thead>
                            <tbody>
                            {ls.map((row,i)=>{

                                return(
                                    <tr key={"i"+i}>
                                        <td>{row.key}</td>
                                        <td>{row.val}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>

                        <div className="form-row">
                            <div className="form-group col-md-16">
                                <label htmlFor="inputState">Comment</label>
                                <textarea
                                    id="inputState"
                                    className="form-control"
                                    onChange={(e) => this.handleInput(e, "comment")}
                                    value={this.getValue("comment")}
                                    required={true}
                                />
                            </div>
                        </div>
                    </div>


                </Dialog>

            </MuiThemeProvider>



        )

    }

    renderPendingList = () => {
        const leaves = this.state.PendingLeaves;
        if(leaves.length ===0){
            return(<p>Empty! you don't have pending application available</p>)
        }

        return(
            <table className={"table"}>
                <thead>
                <tr>
                    <th>From</th>
                    <th>To</th>
                    <th>Dur.Type</th>
                    <th>Dur. Value</th>
                    <th>Category</th>
                    <th>Status</th>
                    <th>#</th>
                </tr>
                </thead>
                <tbody>
                {leaves.map((row,index)=>{
                    return(
                        <tr>
                            <td>{row.PeriodFrom}</td>
                            <td>{row.PeriodTo}</td>
                            <td>{row.PeriodType}</td>
                            <td>{row.PeriodValue}</td>
                            <td>{row.Category}</td>
                            <td>{row.AppStatus}</td>
                            <td>
                                <a
                                    onClick={()=>this.viewLeave(row)}
                                    style={{cursor:"pointer",color:"blue"}}
                                >Process</a>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )

    }

    render() {
        const style = {
            position: "relative",
            margin: "50px auto",
            height: "50%",
            minHeight: "50%",
            width: "50%",
            minWidth: "50%",
        }
        return (
            <PageContentWallpaper {...this.props} >
                <div className="row">
                    <div className="col-xs-12">
                        <h4 className={""}>Applications</h4>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col col-lg-12"}>
                        {this.renderLeaveDetail()}
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col col-xs-16 col-lg-12"}>
                        <h2 className={"alert alert-info"}>Pending</h2>
                        {this.renderPendingList()}
                    </div>


                </div>



            </PageContentWallpaper>

        )

    }

}


const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

const mapStateToProps = state => ({
    addingModel: state.addingModel,
    login: state.login
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LeaveApplicationComponent)