import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import AddingModel from '../common/adding-model';

export default class SettingSiteComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.MyModel = [
            {field:"role",desc:"Role",placeholder:"Role",required:true,type:"text",readonly:false,
                filter:true,filterTable:"role",filterKey:"name",filterValue:"description"},
            {
                field: "costworker",
                desc: "Cost Worker",
                placeholder: "Cost Worker",
                required: true,
                type: "float",
                readonly: false,
                staticValue:""
            },
            {
                field: "costcompany",
                desc: "Cost Company",
                placeholder: "Cost Company",
                required: true,
                type: "float",
                readonly: false,
                staticValue:""
            },
            {
                field: "costclient",
                desc: "Cost Client",
                placeholder: "Cost Client",
                required: true,
                type: "float",
                readonly: false,
                staticValue:""
            },

        ];
        this.ModelName = "rate";
        this.Crum = "/crum"
        this.PrimaryKey = "org";

        /*
        Name text,
	Username text,
	Surname text,
	Email text,
	Role text,
	Department text,
	Phone text,
         */
    }

    render() {
        return (


            <PageContentWallpaper {...this.props} >

                <div className="row">
                    <div className="col-xs-12">
                        <h2>Rate Setting</h2>
                    </div>

                </div>

                <div className="row">
                    <div className="col-xs-12">
                        <AddingModel
                            MyModel={this.MyModel}
                            ModelName={this.ModelName}
                            ListDisplayKey={["Role", "CostWorker","CostCompany","CostClient"]}
                            ListDeleteKey={[
                                {field: this.PrimaryKey, type: "string"},
                                {field: "role", type: "string"}
                            ]}
                            Crum={this.Crum}
                            PrimaryKey={this.PrimaryKey}
                        />
                    </div>
                </div>


            </PageContentWallpaper>


        );
    }
}