import React, {Component} from 'react';
import MultipleDatePicker from 'react-multiple-datepicker';
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';

import {connect} from 'react-redux'
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';
import MonthActivityCalendar from "../common/comp-calendar";
import "../../asset/css/attendance.css";
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';


class AttendanceNewComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organisation: "",
            workerUsername: "",
            Clients: [],
            Sites: [],
            Projects: [],
            Tasks: [],
            Attendances: [],
            employee: null,
            Employees: [],
            DashboardDaily: {},
            selectedClient: "",
            selectedSite: "",
            selectedProject: "",
            selectedTask: "",
            startDate: new Date(),
            hours: "",
            Comment: "",
            billableHours: "yes",
            startHour: moment(),
            endHour: moment(),

            wHour: "00",
            wMinute: "00",

            //Dashboard
            RateWorker: 250,
            RateClient: 300,
            RateCompany: 275,

            OptionsBillable: [
                {name: "yes", description: "Yes"},
                {name: "no", description: "No"},
            ],
            DataTasks: [],
            CurrentMonthAttendance: [],
        }

    }

    componentWillMount() {
        const user = GetUserToken();
        this.setState({organisation: user.OrgCode});
    }

    async componentDidMount() {
        await this.fetchClients();
        await this.fetchSites();
        await this.fetchProjects();
        await this.fetchTasks();
        await this.fetchAttendances();
        await this.findDashboard();
        await this.fetchEmployees();
        await this.findCurrentMonthAttendance();
    }

    dashDates = () => {
        const dateContext = this.state.dateContext;
        return {
            currentDate: moment().format('YYYY-MM-DD'),
            firstDateOfMonth: moment(dateContext).startOf('month').format('YYYY-MM-DD'),
            lastDateOfMonth: moment(dateContext).endOf('month').format('YYYY-MM-DD'),
            firstDateOfWeek: moment(dateContext).startOf('week').format('YYYY-MM-DD'),
            lastDateOfWeek: moment(dateContext).endOf('week').format('YYYY-MM-DD'),
        }
    }

    /*value={this.getNewWorkingHour("wHour")}
    onChange={(e)=>this.handleNewWorkingHour(e,"wHour")}*/
    handleNewWorkingHour=(e, key)=>{
        let tmp = this.state;
        tmp[key] = e.target.value;
        //todo let calculate working hour
        let my = tmp.wHour+"."+tmp.wMinute;
        tmp.hours = my;
        this.setState(tmp);
    }

    getNewWorkingHour = (key) => {
        return this.state[key];
    }
    handleWorkingHour = (event, key) => {
        let myTime = moment(event).format("HH:mm");
        console.log("handleWorkingHour > ", myTime, " > ", key)
        this.setState({
            [key]: event
        });

        this.calculateWorkingHours();
    }

    calculateWorkingHours = () => {


        const date = moment().format("YYYY-MM-DD");
        //console.log("Current Date > ",date);

        if (this.state.startHour === "" || this.state.endAdornment === "") {
            return
        }

        if (this.state.startHour === "00:00" || this.state.endAdornment === "00:00") {
            return
        }

        let now = moment(this.state.endHour);
        let then = moment(this.state.startHour);

        let ms = now.diff(then);
        let d = moment.duration(ms);
        let s = Math.floor(d.asHours()) + moment.utc(ms).format(".mm");
        console.log("Total hour > ", ms, " > ", s);

        this.setState({
            hours: s
        })
    }

    handleChangeWorkDate = (date) => {
        this.setState({
            startDate: date
        });
    }


    findCurrentMonthAttendance = async () => {
        let _this = this;
        const dates = this.dashDates();
        let hub = {};
        hub.StartDate = dates.firstDateOfMonth;
        hub.EndDate = dates.lastDateOfMonth
        hub.org = this.state.organisation;
        let backend = eConfig.mainBackend;
        let endpoint = "/api/attendance/get/period";

        //console.log("findCurrentMonthAttendance send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("findCurrentMonthAttendance response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                let ls = [];

                for (let i in data) {
                    const row = data[i];

                    ls.push({
                        date: row.WorkDate,
                        task: row.Task,
                        start: row.StartTime,
                        end: row.EndTime,
                        duration: row.Hours,
                        billable: row.BillableHours,
                        comment: row.Comment,
                        client: row.Client,
                        workerusername: row.WorkerUsername,
                    })
                }
                _this.setState({
                    CurrentMonthAttendance: data,
                    DataTasks: ls
                })

            }
        });
    }
    findDashboard = async () => {
        let _this = this;
        let state = this.state;
        let user = GetUserToken();
        let myDate = moment(this.state.startDate).format("YYYY-MM");
        let hub = {};
        hub.StartDate = myDate + "-01";
        hub.EndDate = myDate + "-31"
        hub.org = this.state.organisation;
        hub.RequestType = "agent" // Agent, client, service

        hub.WorkerUsername = user.Username;
        hub.WorkerRole = "agent";
        hub.WorkerDept = ""

        hub.RateType = "hours";
        hub.RateWorker = state.RateWorker;
        hub.RateClient = state.RateClient;
        hub.RateCompany = state.RateCompany;
        //console.log("findDashboard => ", hub);

        let backend = eConfig.mainBackend;
        let endpoint = "/api/dashboard/daily";

        //console.log("fetchingAll send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("findDashboard response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                _this.setState({
                    DashboardDaily: data
                })

            }
        });

    }
    fetchEmployees = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: this.state.organisation,
            Type: "string"
        }];
        await this.fetchingAll("worker", conds, (data) => {
            //console.log("fetchEmployees :> ", data);
            const user = GetUserToken();
            let currrentEmp = {};
            for (let i in data) {
                const row = data[i];
                if (row.username === user.Username) {
                    currrentEmp = row;
                }
            }

            _this.setState({
                employee: currrentEmp,
                Employees: data
            });

        });
    }
    fetchAttendances = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: this.state.organisation,
            Type: "string"
        }];
        await this.fetchingAll("attendance", conds, (data) => {
            //console.log("fetchAttendances :> ", data);
            _this.setState({
                Attendances: data,
            });
        });
    }
    fetchTasks = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: this.state.organisation,
            Type: "string"
        }];
        await this.fetchingAll("task", conds, (data) => {
            _this.setState({
                Tasks: data,
            });
        });
    }
    fetchProjects = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: this.state.organisation,
            Type: "string"
        }];
        await this.fetchingAll("project", conds, (data) => {
            _this.setState({
                Projects: data,
            });
        });
    }
    fetchSites = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: this.state.organisation,
            Type: "string"
        }];
        await this.fetchingAll("site", conds, (data) => {
            _this.setState({
                Sites: data,
            });
        });
    }
    fetchClients = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: this.state.organisation,
            Type: "string"
        }];
        await this.fetchingAll("client", conds, (data) => {
            _this.setState({
                Clients: data,
            });
        });
    }
    fetchingAll = async (table, conds, callback) => {

        let hub = {
            Fields: [],
            DbName: "timeattendance",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        //console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            ////console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }

    selectionBox = (label, sourceList, sourceField, sourceLabel, stateKey, handleFunc, conds, required) => {
        const state = this.state;

        let ls = [];
        let data = [];
        if (typeof this.state[sourceList] !== "undefined") {
            data = this.state[sourceList];
        }

        for (let i in data) {
            const row = data[i];
            const field = row[sourceField];
            const desc = row[sourceLabel];

            let boo = true;
            for (let a in conds) {
                const checkEntyKey = conds[a].entry;
                const checkStateKey = conds[a].state;
                const checkEntryVal = row[checkEntyKey];
                const checkStateVal = state[checkStateKey];
                if (checkEntryVal !== checkStateVal) {
                    boo = false;
                }

            }

            if (boo) {
                ls.push({
                    field: field,
                    desc: desc
                })
            }
        }

        const _this = this;
        const setValue = (e) => {
            let tmp = _this.state;
            tmp[stateKey] = e.target.value;
            _this.setState(tmp)
        }
        const getValue = () => {
            let tmp = _this.state;
            return tmp[stateKey];
        }

        return (
            <div className="form-row">
                <div className="form-group col-md-16">
                    <label htmlFor="inputState">{label}</label>
                    <select
                        id="inputState"
                        className="form-control"
                        onChange={(e) => setValue(e)}
                        value={getValue()}
                        required={required}
                    >
                        <option value="" selected>Choose...</option>
                        {ls.map((row, index) => {
                            return (
                                <option key={index} value={row.field}>{row.desc}</option>
                            )
                        })}

                    </select>
                </div>
            </div>
        )
    }

    extractedDateTimeFrom = () => {
        //let myDateTime  = this.state.startDate.toDateString();
        let myDate = moment(this.state.startDate).format("YYYY-MM-DD");
        //console.log("extractedDateTimeFrom > ", myDate);
        return myDate
    }

    submitAttendance = async (e) => {
        e.preventDefault();
        const state = this.state;
        /*if (state.startHour === "") {
            return alert("Validation err please provide your START Time!");
        }
        if (state.endHour === "") {
            return alert("Validation err please provide your END Time!");
        }*/
        if (state.hours === "" || state.hours === "0"|| state.hours === "00"|| state.hours === "00.00") {
            return alert("Validation err working hours can't be Zero or blank!");
        }

        if (!window.confirm("Are you sure that you want to submit this \ntask: " + this.state.selectedTask + "\nDate: " + this.state.startDate.toDateString())) {
            return
        }

        this.extractedDateTimeFrom();
        let user = GetUserToken();
        const date = this.extractedDateTimeFrom();

        let username = user.Username;
        let department = state.employee.department;
        let role = state.employee.role;
        if (state.workerUsername !== "") {
            username = state.workerUsername;
            for (let i in state.Employees) {
                const row = state.Employees[i];
                if (row.username === username) {
                    department = row.department;
                    role = row.role;
                }
            }
        }

        //
        let hub = {};
        hub.Activities = [{
            Org: state.organisation,
            Username: username,
            ClientName: state.selectedClient,
            ClientDepartment: state.selectedProject,
            ActivityName: state.selectedTask,
            Site: state.selectedSite,
            Hours: parseFloat(state.hours),
            BillableHours: state.billableHours,
            StartTime: state.startHour + ":00",
            EndTime: state.endHour + ":00",
            WorkDate: date,
            WorkRole: role,
            WorkDepartment: department,
            Comment: state.Comment,
        }];


        console.log("Submit Attendance send > ", hub);

        let backend = eConfig.mainBackend;
        let endpoint = "/api/attendance/new";
        const _this = this;

        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("Submit Attendance response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Attendance Clock submit successful");
                _this.setState({
                    selectedClient: "",
                    selectedSite: "",
                    selectedProject: "",
                    selectedTask: "",
                    startDate: new Date(),
                    hours: "",
                    Comment: "",
                    startHour: "",
                    endHour: "",
                });

                _this.findCurrentMonthAttendance();

                //TODO reinitialize state
            }
        });

    }

    handleInput = (e, key) => {
        let tmp = this.state;
        tmp[key] = e.target.value;
        this.setState(tmp);
    }
    getValue = (key) => {
        if (typeof this.state[key] !== "undefined") {
            return this.state[key]
        }
        return "";
    }

    isAdmin = () => {
        const user = GetUserToken();
        console.log("Role :> ", user);
        if (user.Role === "super" || user.Role === "admin") {
            return true
        }
        return false
    }

    loadSelectedEmpAttendance = () => {

    }

    render() {
        const style = {
            position: "relative",
            margin: "50px auto",
            height: "50%",
            minHeight: "50%",
            width: "50%",
            minWidth: "50%",
        }
        let targetWorker = GetUserToken().Username;
        if (this.state.workerUsername !== "") {
            targetWorker = this.state.workerUsername;
        }

        console.log("Target worker: ", targetWorker);
        return (


            <PageContentWallpaper {...this.props} >

                <div className="row">
                    <div className="col-xs-12">
                        <h2 className={"alert alert-info"}>Attendant Register (Time sheet)</h2>
                    </div>

                </div>

                {/*<div className="row">
                    <div className="col-xs-12">
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputState">Work Date (MM/DD/YYYY)</label>
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={this.handleChangeWorkDate}
                                    className="form-control"
                                />
                                <label>{this.state.startDate.toDateString()}</label>
                            </div>
                        </div>

                    </div>
                </div>*/}

                <div className="row">
                    <div className="col-xs-5">
                        <h3 style={{color: "red", textAlign: "center"}}>Current Month Attendant</h3>
                        <hr/>
                        <MonthActivityCalendar
                            {...this.props}
                            onDayClick={(e, day) => this.onDayClick(e, day)}
                            style={style} width="302px" height="302px"
                            data={this.state.DataTasks}
                            targetWorker={targetWorker}
                        />
                    </div>
                    <div className="col col-xs-1" style={{
                        borderWidth: 2,
                        borderColor: "#00aced",
                        borderRadius: 5,
                        width: 2,
                        height: "100%",
                        minHeight: "100%",
                        maxHeight: "100%",
                        backgroundColor: "black"
                    }}>
                        &nbsp;
                    </div>
                    <div className="col-xs-6 myBorderLeftDiv">
                        <h3 style={{color: "red", textAlign: "center"}}>New Attendance</h3>
                        <hr/>

                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputState">Work Date (MM/DD/YYYY)</label>
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={this.handleChangeWorkDate}
                                    className="form-control"
                                />

                            </div>
                        </div>
                        <form onSubmit={this.submitAttendance}>

                            {this.isAdmin() && this.selectionBox(
                                "Employee",
                                "Employees",
                                "username",
                                "name",
                                "workerUsername",
                                this.loadSelectedEmpAttendance,
                                [],
                                true
                            )}

                            {this.selectionBox(
                                "Client",
                                "Clients",
                                "name",
                                "description",
                                "selectedClient",
                                null,
                                [],
                                true
                            )}

                            {/*{this.selectionBox(
                                "Site (Optional)",
                                "Sites",
                                "name",
                                "description",
                                "selectedSite",
                                null,
                                [{entry: "client", state: "selectedClient"}],
                                false
                            )}*/}

                            {/* {this.selectionBox(
                                "Project (optional)",
                                "Projects",
                                "name",
                                "description",
                                "selectedProject",
                                null,
                                [{entry: "client", state: "selectedClient"}],
                                true
                            )}*/}

                            {this.selectionBox(
                                "Service rendered",
                                "Tasks",
                                "name",
                                "description",
                                "selectedTask",
                                null,
                                [
                                    /*{entry: "client", state: "selectedClient"},
                                    {entry: "project", state: "selectedProject"},*/
                                ],
                                true
                            )}

                            {this.selectionBox(
                                "Is Billable?",
                                "OptionsBillable",
                                "name",
                                "description",
                                "billableHours",
                                null,
                                [],
                                true
                            )}


                            <div className="form-row">
                                <div className="form-group col-md-16">
                                    <label htmlFor="inputState">Comment</label>
                                    <textarea
                                        id="inputState"
                                        className="form-control"
                                        onChange={(e) => this.handleInput(e, "Comment")}
                                        value={this.getValue("Comment")}
                                        required={false}
                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-16">
                                    <label htmlFor="inputState">Hours Worked</label>
                                    <input
                                        id="inputState"
                                        className="form-control"
                                        onChange={(e) => this.handleInput(e, "hours")}
                                        value={this.getValue("hours")}
                                        required={true}
                                        readOnly={true}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-6 alert alert-danger">
                                    <label htmlFor="inputState">Hour</label>
                                    <select
                                        className="form-control"
                                        size={5}
                                        value={this.getNewWorkingHour("wHour")}
                                        onChange={(e) => this.handleNewWorkingHour(e, "wHour")}
                                    >
                                        <option value="00">00</option>
                                        <option value="01">01</option>
                                        <option value="02">02</option>
                                        <option value="03">03</option>
                                        <option value="04">04</option>
                                        <option value="05">05</option>
                                        <option value="06">06</option>
                                        <option value="07">07</option>
                                        <option value="08">08</option>
                                        <option value="09">09</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-6 alert alert-danger">
                                    <label htmlFor="inputState">Minute</label>
                                    <select
                                        className="form-control"
                                        size={5}
                                        value={this.getNewWorkingHour("wMinute")}
                                        onChange={(e) => this.handleNewWorkingHour(e, "wMinute")}
                                    >
                                        <option value="00">00</option>
                                        <option value="15">15</option>
                                        <option value="30">30</option>
                                        <option value="45">45</option>
                                    </select>
                                </div>
                            </div>

                            {/*<div className="form-row">
                                <div className="form-group col-md-6 alert alert-danger">
                                    <label htmlFor="inputState">Start Time</label>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker
                                            margin="normal"
                                            value={this.getValue("startHour")}
                                            onChange={(e) => this.handleWorkingHour(e, "startHour")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="form-group col-md-6 alert alert-danger">
                                    <label htmlFor="inputState">End Time</label>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker
                                            margin="normal"
                                            value={this.getValue("endHour")}
                                            onChange={(e) => this.handleWorkingHour(e, "endHour")}
                                        />
                                    </MuiPickersUtilsProvider>

                                </div>
                            </div>*/}


                            <div className="form-group col-md-12">
                                <button
                                    type={"submit"}
                                    className={"btn btn-primary pull-right"}
                                >Submit
                                </button>
                            </div>
                        </form>
                    </div>


                </div>


            </PageContentWallpaper>


        );
    }
}


const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

const mapStateToProps = state => ({
    addingModel: state.addingModel,
    login: state.login
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AttendanceNewComponent)