import {SAVE_NAVIGATIONS} from '../constant';

const initial = {
    home: {
        desc: "Home", data: [
            {menu: "home", display: "Home"},
        ]
    },
    attendance: {
        desc: "Attendance",
        data: [
            {menu: "attendance-new", display: "New Attendant"},
        ]
    },
    leave: {
        desc: "Leave",
        data: [
            {menu: "leave-application", display: "Application"},
            {menu: "leave-status", display: "Status"},
            {menu: "leave-manager", display: "Manager"},
            {menu: "leave-taken", display: "Taken"},
            {menu: "leave-category", display: "Set-Category"},
        ]
    },
    dashboard: {
        desc: "Dashboard",
        data: [
            {menu: "dashboard-daily", display: "Date Range"},
           /* {menu: "dashboard-report", display: "Report"},*/
        ]
    },
    setting: {
        desc: "Settings",
        data: [
            {menu: "setting-client", display: "Client"},
            {menu: "setting-project", display: "Project"},
            {menu: "setting-task", display: "Service Rendered"},

            {menu: "setting-role", display: "Role"},
            /*{menu: "setting-rate", display: "Rate"},*/
            {menu: "setting-department", display: "Department"},
            {menu: "setting-employee", display: "Employee"},
            ]
    }

}


const reduceNavigations = (state = initial, action) => {
    let newState = state;
    switch (action.type) {
        case SAVE_NAVIGATIONS:
            newState = action.payload;
            return Object.assign({}, newState);
        default:
            return state
    }
}

export default reduceNavigations;