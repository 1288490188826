import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import { mapStateToProps, mapDispatchToProps} from '../component/common/redux-dispatch-to-props';


import Wallpaper from '../component/common/ef-wallper';
import MainComponent from '../component/leave/comp-leave-status';

class ConLeaveStatus extends Component {
    render() {
        return (
            <Wallpaper hasHeader={true} hasFooter={true} {...this.props}>
                <MainComponent {...this.props}/>
            </Wallpaper >
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConLeaveStatus)

