import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import AddingModel from '../common/adding-model';

export default class SettingDepartmentComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.MyModel = [
            {
                field: "name",
                desc: "Name",
                placeholder: "name",
                required: true,
                type: "text",
                readonly: false,
                staticValue:""
            },
            {
                field: "description",
                desc: "Description",
                placeholder: "Description",
                required: true,
                type: "text",
                readonly: false,
                staticValue:""
            },

        ];
        this.ModelName = "department";
        this.Crum = "/crum";
        this.PrimaryKey = "org";
    }

    render() {
        return (


            <PageContentWallpaper {...this.props} >

                <div className="row">
                    <div className="col-xs-12">
                        <h2>Company Department Setting</h2>
                    </div>

                </div>

                <div className="row">
                    <div className="col-xs-12">
                        <AddingModel
                            MyModel={this.MyModel}
                            ModelName={this.ModelName}
                            ListDisplayKey={["Name"]}
                            ListDeleteKey={[
                                {field: this.PrimaryKey, type: "string"},
                                {field: "name", type: "string"}
                            ]}
                            Crum={this.Crum}
                            PrimaryKey={this.PrimaryKey}
                        />
                    </div>
                </div>


            </PageContentWallpaper>


        );
    }
}