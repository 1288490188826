import React, {Component} from 'react';
import {Router, Route, Switch} from 'react-router';
import createHistory from 'history/createHashHistory';

import {Provider} from 'react-redux';
import { createStore,applyMiddleware } from 'redux';
import logger from 'redux-logger'
import rootReducer from './reducers';

import loginScreen from './container/con-login';
import registerScreen from './container/con-register';
import pageNotFindScreen from './container/con-401';
import homeScreen from './container/con-home';

import settingClientScreen from './container/con-setting-client';
import settingSiteScreen from './container/con-setting-site';
import settingProjectScreen from './container/con-setting-project';
import settingTaskScreen from './container/con-setting-task';
import settingRoleScreen from './container/con-setting-role';
import settingRateScreen from './container/con-setting-rate';
import settingEmployeeScreen from './container/con-setting-employee';
import settingDepartmentScreen from './container/con-setting-department';

import attendanceNewScreen from './container/con-attendance-new';

import dashboardDailyScreen from './container/con-dashboard-daily';
import dashboardCalendarcreen from './container/con-calendar';

import ScreenLeaveCategory from "./container/con-leave-category";
import ScreenLeaveApplication from "./container/con-leave-application";
import ScreenLeaveManager from "./container/con-leave-manager";
import ScreenLeaveStatus from "./container/con-leave-status";
import ScreenLeaveTaken from "./container/con-leave-taken";


const history = createHistory();
const store = createStore(
    rootReducer,
    applyMiddleware(logger)
);

class App extends Component {
    render() {
        return (
            <Provider store={store}>
            <Router history={history}>
                <Switch>

                    <Route exact path="/" component={loginScreen}/>
                    <Route path="/login" component={loginScreen}/>
                    <Route path="/register" component={registerScreen}/>
                    <Route path="/home" component={homeScreen}/>

                    <Route path="/setting-client" component={settingClientScreen}/>
                    <Route path="/setting-site" component={settingSiteScreen}/>
                    <Route path="/setting-project" component={settingProjectScreen}/>
                    <Route path="/setting-task" component={settingTaskScreen}/>
                    <Route path="/setting-role" component={settingRoleScreen}/>
                    <Route path="/setting-rate" component={settingRateScreen}/>
                    <Route path="/setting-employee" component={settingEmployeeScreen}/>
                    <Route path="/setting-department" component={settingDepartmentScreen}/>


                    <Route path="/attendance-new" component={attendanceNewScreen}/>

                    <Route path="/dashboard-daily" component={dashboardDailyScreen}/>
                    <Route path="/dashboard-calendar" component={dashboardCalendarcreen}/>

                    <Route path="/leave-category" component={ScreenLeaveCategory}/>
                    <Route path="/leave-application" component={ScreenLeaveApplication}/>
                    <Route path="/leave-manager" component={ScreenLeaveManager}/>
                    <Route path="/leave-status" component={ScreenLeaveStatus}/>
                    <Route path="/leave-taken" component={ScreenLeaveTaken}/>




                    <Route component={pageNotFindScreen}/>
                </Switch>
            </Router>
            </Provider>

        );
    }
}

export default App;

/*
<div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
 */