import React, {Component} from 'react';
import MultipleDatePicker from 'react-multiple-datepicker';
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';

import {connect} from 'react-redux'
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';
import MonthActivityCalendar from "../common/comp-calendar";
import "../../asset/css/attendance.css";
import FlatButton from "material-ui/FlatButton";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Dialog from "material-ui/Dialog";

class LeaveApplicationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organisation:"",
            Data: [],
        }

    }

    componentWillMount() {
        const user = GetUserToken();
        this.setState({organisation: user.OrgCode});
    }

    async componentDidMount() {
        await this.fetchLeaveTaken();
    }


    fetchLeaveTaken = async () => {
        let _this = this;
        const user = GetUserToken();
        let conds = [{
            Key: "org",
            Val: this.state.organisation,
            Type: "string"
        },{
            Key: "ApplicantRef",
            Val: user.Username,
            Type: "string"
        },];

        await this.fetchingAll("applicantLeave", conds, (data) => {
            _this.setState({
                Data: data,
            });
        });
    }
    fetchingAll = async (table, conds, callback) => {
        let hub = {
            Fields: [],
            DbName: "timeattendance",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";
        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }


    renderLeaveTaken = () => {
        const leaves = this.state.Data;
        if(leaves.length ===0){
            return(<p>Empty!</p>)
        }

        return(
            <table className={"table"}>
                <thead>
                <tr>
                    <th>Category</th>
                    <th>Apply Date</th>
                    <th>Approve Date</th>
                    <th>leave Date</th>
                    <th>Approve By</th>
                    <th>Manager Note</th>
                </tr>
                </thead>
                <tbody>
                {leaves.map((row,index)=>{

                    return(
                        <tr>
                            <td>{row.category}</td>
                            <td>{row.applydate}</td>
                            <td>{row.approvaldate}</td>
                            <td>{row.leavedate}</td>
                            <td>{row.profile.QaFullname}</td>
                            <td>{row.approvalnote}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )

    }

    render() {


        return (
            <PageContentWallpaper {...this.props} >
                <div className="row">
                    <div className="col-xs-12">
                        <h4 className={""}>Leave Taken</h4>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col col-xs-16 col-lg-12"}>
                        <h2 className={"alert alert-info"}>Approved Day</h2>
                        {this.renderLeaveTaken()}
                    </div>


                </div>



            </PageContentWallpaper>

        )

    }

}


const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

const mapStateToProps = state => ({
    addingModel: state.addingModel,
    login: state.login
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LeaveApplicationComponent)