import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import AddingModel from '../common/adding-model';

export default class LeaveCategoryComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.MyModel = [
            {
                field: "name",
                desc: "Name",
                placeholder: "Name",
                required: true,
                type: "text",
                readonly: false,
                staticValue:""
            },{
                field: "description",
                desc: "Description",
                placeholder: "Description",
                required: true,
                type: "text",
                readonly: false,
                staticValue:""
            },
            {field:"LeaveType",desc:"Leave Type",placeholder:"Leave Type",required:true,type:"option",readonly:false,
                options:[{val:"paid",desc:"Paid"},{val:"no paid",desc:"No Paid"}]},
            {field:"Accumulative",desc:"Accumulative",placeholder:"Accumulative",required:true,type:"option",readonly:false,
                options:[{val:"yes",desc:"Yes"},{val:"no",desc:"No"}]},
            {field:"AccumulativeCircle",desc:"Accumulative Circle",placeholder:"Accumulative Circle",required:false,type:"option",readonly:false,
                options:[{val:"monthly",desc:"monthly"},{val:"weekly",desc:"weekly"}]},
            {
                field: "AccumulativeValue",
                desc: "Accumulative Value",
                placeholder: "Accumulative Value",
                required: false,
                type: "float",
                readonly: false,
                staticValue:""
            }

        ];
        this.ModelName = "leavecategory";
        this.Crum = "/crum"
        this.PrimaryKey = "org";

        /*


	AccumulativeValue  float64
	AccumulativeCircle string // monthly,weekly,daily....
         */
    }

    render() {
        return (


            <PageContentWallpaper {...this.props} >

                <div className="row">
                    <div className="col-xs-12">
                        <h2>Leave Category Setting</h2>
                    </div>

                </div>

                <div className="row">
                    <div className="col-xs-12">
                        <AddingModel
                            MyModel={this.MyModel}
                            ModelName={this.ModelName}
                            ListDisplayKey={["Name","LeaveType", "Accumulative"]}
                            ListDeleteKey={[
                                {field: this.PrimaryKey, type: "string"},
                                {field: "name", type: "string"}
                            ]}
                            Crum={this.Crum}
                            PrimaryKey={this.PrimaryKey}
                        />
                    </div>
                </div>


            </PageContentWallpaper>


        );
    }
}