import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import AddingModel from '../common/adding-model';

export default class SettingSiteComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.MyModel = [
            {
                field: "username",
                desc: "Username",
                placeholder: "username",
                required: true,
                type: "text",
                readonly: false,
                staticValue:""
            },
            {field:"role",desc:"Role",placeholder:"Role",required:true,type:"text",readonly:false,
                filter:true,filterTable:"role",filterKey:"name",filterValue:"description"},
            {
                field: "name",
                desc: "Name",
                placeholder: "Name",
                required: true,
                type: "text",
                readonly: false,
                staticValue:""
            },{
                field: "surname",
                desc: "Surname",
                placeholder: "Surname",
                required: true,
                type: "text",
                readonly: false,
                staticValue:""
            },{
                field: "email",
                desc: "Email (Same as Username)",
                placeholder: "Email (Same as Username)",
                required: true,
                type: "text",
                readonly: false,
                staticValue:""
            },{
                field: "phone",
                desc: "Phone",
                placeholder: "Phone",
                required: true,
                type: "text",
                readonly: false,
                staticValue:""
            },
            {field:"department",desc:"Department",placeholder:"Department",required:true,type:"text",readonly:false,
                filter:true,filterTable:"department",filterKey:"name",filterValue:"description"},
            {
                field: "CostWorker",
                desc: "Cost To Company",
                placeholder: "Cost To Company",
                required: true,
                type: "float",
                readonly: false,
                staticValue:""
            },
            /*{
                field: "CostCompany",
                desc: "Cost To Company",
                placeholder: "Cost To Company",
                required: true,
                type: "float",
                readonly: false,
                staticValue:""
            },*/
            {
                field: "CostClient",
                desc: "Cost To Client",
                placeholder: "Cost To Client",
                required: true,
                type: "float",
                readonly: false,
                staticValue:""
            },
            {
                field: "BudgetHour",
                desc: "Budget Hour",
                placeholder: "Budget Hour",
                required: true,
                type: "float",
                readonly: false,
                staticValue:""
            },

        ];
        this.ModelName = "worker";
        this.Crum = "/crum"
        this.PrimaryKey = "org";

        /*
         */
    }

    render() {
        return (


            <PageContentWallpaper {...this.props} >

                <div className="row">
                    <div className="col-xs-12">
                        <h2>Employee Database Setting</h2>
                    </div>

                </div>

                <div className="row">
                    <div className="col-xs-12">
                        <AddingModel
                            MyModel={this.MyModel}
                            ModelName={this.ModelName}
                            ListDisplayKey={["Name","Surname", "Username","Phone","Email","Department"]}
                            ListDeleteKey={[
                                {field: this.PrimaryKey, type: "string"},
                                {field: "username", type: "string"}
                            ]}
                            Crum={this.Crum}
                            PrimaryKey={this.PrimaryKey}
                        />
                    </div>
                </div>


            </PageContentWallpaper>


        );
    }
}