import { combineReducers } from 'redux';

import menus from './reducer/reducer-menus';
import login from './reducer/reducer-login';
import navigations from './reducer/reducer-navigations';
import addingModel from "./reducer/reducer-adding-model";


export default combineReducers({
    menus,
    login,
    navigations,
    addingModel
})