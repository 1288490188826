
import React,{Component} from 'react';
import moment from "moment";
import logo from "../../asset/img/logo.png";
import '../../asset/css/calendar.css';


import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
/*import { ListItem} from 'material-ui/List';
import Checkbox from 'material-ui/Checkbox';
import RaisedButton from 'material-ui/RaisedButton';
import {Tabs, Tab} from 'material-ui/Tabs';
import Badge from 'material-ui/Badge';*/


const style={
    position:"relative",
    margin:"50px auto",
    height:"50%",
    minHeight:"50%",
    width:"50%",
    minWidth:"50%",
}
class DashboardCalendar extends Component{
    constructor(props){
        super(props);
        this.width = props.width || "350px";
        this.style = props.style || {};
        this.state = {
            dateContext:moment(),
            today:moment(),
            showMonthPopup:false,
            showYearPopup:false,
            showYearNav:false,
            showTasks:false,
            selectedTask:null,
        }
    }

    weekdays = moment.weekdays();
    weekdaysShort = moment.weekdaysShort();
    months=moment.months();

    year = () => {
        return this.state.dateContext.format("Y");
    }
    month = () => {
        return this.state.dateContext.format("MMMM");
    }
    monthNumber = () => {
        return this.state.dateContext.format("MM");
    }
    daysInMonth = () => {
        console.log("daysInMonth ):(---> ",this.state.dateContext.daysInMonth());
        return this.state.dateContext.daysInMonth();
    }
    currentDate = () => {
        //console.log("currentDate: ", this.state.dateContext.get("date"));
        return this.state.dateContext.get("date");
    }
    currentDay = () => {
        return this.state.dateContext.format("D");
    }
    currentDayNumber = () => {
        return this.state.dateContext.format("DD");
    }
    currentDate=()=>{

    }
    firstDayOfMonth = () => {
        let dateContext = this.state.dateContext;
        let firstDay = moment(dateContext).startOf('month').format('d'); // Day of week 0...1..5...6
        return firstDay;
    }

    setMonth=(month)=>{
        let monthNo = this.months.indexOf(month);
        let dateContext  = Object.assign({},this.state.dateContext);
        dateContext = moment(dateContext).set("month",monthNo);
        this.setState({
            dateContext:dateContext
        })
    }

    prevMonth=()=>{
        let dateContext  = Object.assign({},this.state.dateContext);
        dateContext = moment(dateContext).add(-1,"month");
        this.setState({
            dateContext:dateContext
        })
        this.props.onPrevMonth && this.props.onPrevMonth();
    }
    nextMonth=()=>{
        let dateContext  = Object.assign({},this.state.dateContext);
        dateContext = moment(dateContext).add(1,"month");
        this.setState({
            dateContext:dateContext
        })
        this.props.onNextMonth && this.props.onNextMonth();
    }

    onSelectChange=(e,data)=>{
        this.setMonth(data);
        this.props.onMonthChange && this.props.onMonthChange();
    }
    SelectList=(props)=>{
        let popup = props.data.map((data,i)=>{
            return(
                <div key={i * 78} className={""}>
                    <a
                        style={{cursor:"pointer"}}
                        onClick={(e)=>this.onSelectChange(e,data)}
                    >
                        {data}
                    </a>
                </div>
            )
        });

        return (
            <div className={"month-popup"}>
                {popup}
            </div>
        );
    }
    onChangeMonth=(e,month)=>{
        this.setState({
            showMonthPopup: !this.state.showMonthPopup
        })
    }
    MonthNav=()=>{
        return(
            <span className={"label-month"}
                  onClick={(e)=>this.onChangeMonth(e,this.month())}
            >
                <b>{this.month()}</b>
                {this.state.showMonthPopup &&(
                    <this.SelectList data={this.months} />
                )}

            </span>
        )
    }

    showYearEditor=()=>{
        this.setState({
            showYearNav:true
        })
    }
    onKeyUpYear=(e)=>{
        if(e.which ===13 || e.which === 27){
            this.setYear(e.target.value);
            this.setState({
                showYearNav:false
            })
        }
    }
    setYear=(year)=>{
        let dateContext = Object.assign({},this.state.dateContext);
        dateContext = moment(dateContext).set("year",year);
        this.setState({
            dateContext:dateContext
        })
    }
    onYearChange=(e)=>{
        this.setYear(e.target.value);
        this.props.onYearChange && this.props.onYearChange(e,e.target.value);


    }
    YearNav=()=>{

        return(
            this.state.showYearNav?
                <input
                    className="editor-year"
                    defaultValue={this.year()}
                    ref={(yearInput)=>this.yearInput = yearInput}
                    onKeyUp={(e)=>this.onKeyUpYear(e)}
                    onChange={(e)=>this.onYearChange(e)}
                    type="number"
                    placeholder="year"
                />
                :
                <span
                    className="label-year"
                    onDoubleClick={(e)=>this.showYearEditor()}
                >
                {this.year()}
            </span>
        )
    }


    onDayClick=(e,day,dateIn)=>{
        const date =this.year()+"-"+this.monthNumber()+"-"+day;
        //this.props.onDayClick && this.props.onDayClick(e,date);

        let o = null;
        for(let i in this.props.data){
            const row = this.props.data[i];
            if(row.date ===dateIn){
                o  = row
            }
        }

        //console.log("++) selected date ",o);
        this.setState({
            showTasks:true,
            selectedTask:o,
        })
    }

    hasTask=(inDate)=>{
        ////console.log("++) ALL Data ",inDate," > ",this.props.data);
        if(inDate.length ===1){
            inDate="0"+inDate
        }

        const targetWorker = this.props.targetWorker;
        for(let i in this.props.data){
            const row = this.props.data[i];
            //console.log("targetWorker:> ",row," == ",targetWorker," > ",(row.workerusername === targetWorker));
            if(row.workerusername ===targetWorker){
                if(row.date ===inDate){
                    return true
                }
            }

        }
        return false
    }

    closeShowDetail=()=>{
        this.setState({
            showTasks:false,
            selectedTask:null,
        })
    }
    renderShowDetail() {

        if(this.state.selectedTask ===null){
            return null
        }

        const actions = [
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.closeShowDetail}
            />,
        ];

        let ls=[];
        const targetWorker = this.props.targetWorker;
        for(let i in this.props.data){
            const row = this.props.data[i];
            console.log("targetWorker:> ",row," == ",targetWorker," > ",(row.workerusername === targetWorker));
            if(row.workerusername === targetWorker){
                if(row.date ===this.state.selectedTask.date){
                    ls.push(row)
                }
            }

        }

        let totAll=0;
        return (
            <MuiThemeProvider>
                <Dialog
                    title={"Activities Detail Date selected"}
                    actions={actions}
                    modal={false}
                    open={this.state.showTasks}
                    onRequestClose={this.closeShowDetail}
                    autoScrollBodyContent={true}
                >

                    <br/>

                    <div className="col-lg-12">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Task</th>
                                <th>Client</th>
                                <th>Comment</th>
                                <th>Is-Billable?</th>
                                <th>Hours</th>
                            </tr>
                            </thead>
                            <tbody>
                            {ls.map((row,i)=>{
                                totAll =this.calculateSummerOfHour(totAll,row.duration);
                                return(
                                    <tr key={"i"+i}>
                                        <td>{row.task}</td>
                                        <td>{row.client}</td>
                                        <td>{row.comment}</td>
                                        <td>{row.billable}</td>
                                        <td>{row.duration}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                            <tfoot>
                            <th colSpan={4} style={{color:"red"}}>Total</th>
                            <th style={{color:"red"}}>{totAll}</th>
                            </tfoot>
                        </table>
                    </div>


                </Dialog>

            </MuiThemeProvider>



        )

    }

    calculateSummerOfHour = (num1, num2) => {
        let decNum1 = (num1 % 1).toFixed(2).substring(2);
        let decNum2 = (num2 % 1).toFixed(2).substring(2);


        let hourNum1 = this.convertEntryToFLoat(num1);// .toFixed(0);
        let hourNum2 = this.convertEntryToFLoat(num2);// .toFixed(0);

        let totalMinuteNun1 = (parseInt(hourNum1) * 60) + parseInt(decNum1);
        let totalMinuteNun2 = (parseInt(hourNum2) * 60) + parseInt(decNum2);

        let data = totalMinuteNun1 + totalMinuteNun2;
        let minutes = data % 60;
        let hours = (data - minutes) / 60;
        let msg = hours + "." + minutes;
        let total = parseFloat(msg).toFixed(2);
        return total
    }

    convertEntryToFLoat = (inNum) => {
        const str1 = "" + inNum;
        const total = parseFloat(str1);
        return total.toFixed(0);
    }



    render(){
        //console.log("currentDay > ",this.currentDay())
        // map the weeekdays i.e. Sun, Mon ...
        let weekdays = this.weekdaysShort.map((day)=>{
            return(
                <td key={day} className={"week-day"}>{day}</td>
            )
        });
        let blanks=[];
        for(let i=0;i < this.firstDayOfMonth();i++){
            blanks.push(
                <td key={i * 80} className={"emptySlot"}>
                    {""}
                </td>
            )
        }

        let daysInMonth=[];
        for(let d=1; d <= this.daysInMonth();d++){
            let className = (d == this.currentDay() ? "day current-day": "day");

            //TODo let hightlight all the date that have task
            let classBadge = "";
            let dayOut = d;
            if(dayOut.toString().length ===1){
                dayOut="0"+dayOut
            }
            const completeDate =this.year()+"-"+this.monthNumber()+"-"+dayOut;
            if(this.hasTask(completeDate)){
                classBadge=" badge badge-secondary"
            }

            daysInMonth.push(
                <td className={className}>
                    <span
                        className={classBadge}
                        onClick={(e)=>this.onDayClick(e,d,completeDate)}
                    >{d}</span>
                </td>
            );
        }

        //console.log("days: ",daysInMonth);

        let totalSlots = [...blanks, ...daysInMonth];
        let rows=[];
        let cells=[];

        totalSlots.forEach((row,i)=>{
            if((i % 7)!==0){
                cells.push(row)
            }else{
                let insertRow = cells.slice();
                rows.push(insertRow);
                cells=[];
                cells.push(row)
            }

            if(i === totalSlots.length - 1){
                let insertRow = cells.slice();
                rows.push(insertRow);
            }
        })



        let trElems =rows.map((d,i)=>{
            return(
                <tr key={i+100}>
                    {d}
                </tr>
            )
        });




        return(



            <div className="calendar-container myContainer" style={this.style}>
                <table className="calendar" style={{minHeight:"100%"}}>
                    <thead >
                    <tr className="calendar-header">
                        <td colSpan={5}>
                            <this.MonthNav />
                            {" "}
                            <this.YearNav />
                        </td>
                        <td colSpan={2} className="nav-month">
                            <i
                                className="prev fa fa-fw fa-chevron-left"
                                onClick={(e)=>this.prevMonth()}
                            >
                            </i>

                            <i
                                className="prev fa fa-fw fa-chevron-right"
                                onClick={(e)=>this.nextMonth()}
                            >
                            </i>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        {weekdays}
                    </tr>
                    {trElems}

                    </tbody>
                </table>

                {this.renderShowDetail()}
            </div>

        )
    }
}

export default DashboardCalendar;