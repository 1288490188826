import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import AddingModel from '../common/adding-model';

export default class SettingTaskComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.MyModel = [
            {
                field: "name",
                desc: "Name",
                placeholder: "name",
                required: true,
                type: "text",
                readonly: false,
                staticValue:""
            },
            {
                field: "description",
                desc: "Description",
                placeholder: "Description",
                required: true,
                type: "text",
                readonly: false,
                staticValue:""
            },

            /*{field:"billable",desc:"Is it Billable?",placeholder:"Is Optional?",required:true,type:"option",readonly:false,
                options:[{val:"yes",desc:"Yes"},{val:"no",desc:"No"}]},*/
            /*{field:"client",desc:"Client",placeholder:"Client",required:true,type:"text",readonly:false,
                filter:true,filterTable:"client",filterKey:"name",filterValue:"description"},*/
            /*{field:"site",desc:"site",placeholder:"Site",required:true,type:"text",readonly:false,
                filter:true,filterTable:"site",filterKey:"name",filterValue:"description",dependency:["client"]},*/
            /*{field:"project",desc:"Project",placeholder:"Project",required:true,type:"text",readonly:false,
                filter:true,filterTable:"project",filterKey:"name",filterValue:"description",dependency:["client"]},*/

        ];
        this.ModelName = "task";
        this.Crum = "/crum"
        this.PrimaryKey = "org"
    }

    render() {
        return (


            <PageContentWallpaper {...this.props} >

                <div className="row">
                    <div className="col-xs-12">
                        <h2>Service rendered </h2>
                    </div>

                </div>

                <div className="row">
                    <div className="col-xs-12">
                        <AddingModel
                            MyModel={this.MyModel}
                            ModelName={this.ModelName}
                            ListDisplayKey={["Name"]}
                            ListDeleteKey={[
                                {field: this.PrimaryKey, type: "string"},
                                {field: "name", type: "string"}
                            ]}
                            Crum={this.Crum}
                            PrimaryKey={this.PrimaryKey}
                        />
                    </div>
                </div>


            </PageContentWallpaper>


        );
    }
}