import React, { Component } from 'react';




import Wallpaper from '../component/common/ef-wallper';
import HomeComponent from '../component/common/comp-calendar';
import connect from "react-redux/es/connect/connect";
import {mapDispatchToProps, mapStateToProps} from "../component/common/redux-dispatch-to-props";
import logo from "../asset/img/logo.png";
import '../asset/css/calendar-app.css';

const style = {
    position: "relative",
    margin: "50px auto"
}

class ConCalendar extends Component {
    constructor(props){
        super(props);
        this.state={
            DataTasks:[
                {date:"2019-05-02",task:"Meeting One",start:"09:30:00",end:"10:00:00",duration:30,billable:"yes",costCompany:250,costClient:350},
                {date:"2019-05-02",task:"Meeting One",start:"09:30:00",end:"10:00:00",duration:30,billable:"yes",costCompany:250,costClient:350},
                {date:"2019-05-05",task:"Meeting Two",start:"09:30:00",end:"10:00:00",duration:30,billable:"yes",costCompany:250,costClient:350},
                {date:"2019-05-06",task:"Meeting One",start:"09:30:00",end:"10:00:00",duration:30,billable:"yes",costCompany:250,costClient:350},
               {date:"2019-05-06",task:"Meeting One",start:"09:30:00",end:"10:00:00",duration:30,billable:"yes",costCompany:250,costClient:350},
                {date:"2019-05-07",task:"Meeting One",start:"09:30:00",end:"10:00:00",duration:30,billable:"yes",costCompany:250,costClient:350},
                {date:"2019-04-20",task:"Meeting Twe",start:"09:30:00",end:"10:00:00",duration:30,billable:"yes",costCompany:250,costClient:350},
            ]
        }
    }
    onDayClick=(e,day)=>{
        alert("Your Choose : "+day)
    }
    render() {
        console.log("ALL_PROPS > ",this.props);
        return (
            <div className="App">
                <header className="App-header">
                    <img src={logo}  className="App-logo" alt="logo" style={{backgroundColor:"white"}}/>
                </header>


                <HomeComponent
                    {...this.props}
                    onDayClick={(e,day)=>this.onDayClick(e,day)}
                    style={style} width="302px" height="302px"
                    data={this.state.DataTasks}
                />
            </div>

        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConCalendar)