import React, {Component} from 'react';
import MultipleDatePicker from 'react-multiple-datepicker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Tabs, Tab} from 'material-ui/Tabs';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Badge from 'material-ui/Badge';
import moment from "moment";
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';

import {connect} from 'react-redux'
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
import ReactHtmlTableToExcel from "react-html-table-to-excel";

const FloatMe = (item) => {
    const str = parseFloat(item);
    return str.toFixed(2)
}
const FloatMeZero = (item) => {
    const str = parseFloat(item);
    return str.toFixed(0)
}

class AttendanceNewComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organisation: "",
            Data: null,
            selectRow: null,

            startDate: new Date(),
            endDate: new Date(),

            Employees: {},
            Rates: {},
            DataTasks: [],
            AllAttendanceRange: [],
            showDetail: false,
            Clients: [],

            showLeave: false,
            leaveUsername: "",
            leaveDate: "",
            leaveData: {},

            agentDetailViewType: "all",
            clientDetailViewType: "all",
        }

    }

    componentWillMount() {
        const user = GetUserToken();
        this.setState({organisation: user.OrgCode});
    }

    async componentDidMount() {
        this.calculateSummerOfHour(7, 7.25)
        await this.fetchEmployees();
        await this.fetchRate();
    }

    convertEntryToFLoat = (inNum) => {
        const str1 = "" + inNum;
        const total = parseFloat(str1);
        return total.toFixed(0);
    }
    calculateSummerOfHour = (num1, num2) => {
        let decNum1 = (num1 % 1).toFixed(2).substring(2);
        let decNum2 = (num2 % 1).toFixed(2).substring(2);


        let hourNum1 = this.convertEntryToFLoat(num1);// .toFixed(0);
        let hourNum2 = this.convertEntryToFLoat(num2);// .toFixed(0);

        let totalMinuteNun1 = (parseInt(hourNum1) * 60) + parseInt(decNum1);
        let totalMinuteNun2 = (parseInt(hourNum2) * 60) + parseInt(decNum2);

        let data = totalMinuteNun1 + totalMinuteNun2;
        let minutes = data % 60;
        let hours = (data - minutes) / 60;
        let msg = hours + "." + minutes;
        let total = parseFloat(msg).toFixed(2);
        return total
    }
    calculateMinusOfHour = (num1, num2) => {
        let decNum1 = (num1 % 1).toFixed(2).substring(2);
        let decNum2 = (num2 % 1).toFixed(2).substring(2);


        let hourNum1 = this.convertEntryToFLoat(num1);// .toFixed(0);
        let hourNum2 = this.convertEntryToFLoat(num2);// .toFixed(0);

        let totalMinuteNun1 = (parseInt(hourNum1) * 60) + parseInt(decNum1);
        let totalMinuteNun2 = (parseInt(hourNum2) * 60) + parseInt(decNum2);

        let data = totalMinuteNun1 - totalMinuteNun2;
        let minutes = data % 60;
        let hours = (data - minutes) / 60;
        let msg = hours + "." + minutes;
        let total = parseFloat(msg).toFixed(2);
        return total
    }

    handleChangeStartDate = (date) => {
        this.setState({
            startDate: date
        });
    }
    handleChangeEndDate = (date) => {
        this.setState({
            endDate: date
        });
    }

    fetchRate = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: this.state.organisation,
            Type: "string"
        }];
        await this.fetchingAll("rate", conds, (data) => {
            console.log("fetchRate :> ", data);
            let ls = {};
            for (let i in data) {
                let row = data[i];
                ls[row.role] = row;
            }
            _this.setState({
                Rates: ls
            });

        });
    }
    fetchEmployees = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: this.state.organisation,
            Type: "string"
        }];
        await this.fetchingAll("worker", conds, (data) => {
            console.log("fetchEmployees :> ", data);
            let ls = {};
            for (let i in data) {
                let row = data[i];
                ls[row.username] = row;
            }
            _this.setState({
                Employees: ls
            });

        });
    }

    fetchingAll = async (table, conds, callback) => {

        let hub = {
            Fields: [],
            DbName: "timeattendance",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }

    selectionBox = (label, sourceList, sourceField, sourceLabel, stateKey, handleFunc, conds) => {
        const state = this.state;

        let ls = [];
        let data = [];
        if (typeof this.state[sourceList] !== "undefined") {
            data = this.state[sourceList];
        }

        for (let i in data) {
            const row = data[i];
            const field = row[sourceField];
            const desc = row[sourceLabel];

            let boo = true;
            for (let a in conds) {
                const checkEntyKey = conds[a].entry;
                const checkStateKey = conds[a].state;
                const checkEntryVal = row[checkEntyKey];
                const checkStateVal = state[checkStateKey];
                if (checkEntryVal !== checkStateVal) {
                    boo = false;
                }

            }

            if (boo) {
                ls.push({
                    field: field,
                    desc: desc
                })
            }
        }

        const _this = this;
        const setValue = (e) => {
            let tmp = _this.state;
            tmp[stateKey] = e.target.value;
            _this.setState(tmp)
        }
        const getValue = () => {
            let tmp = _this.state;
            return tmp[stateKey];
        }

        return (
            <div className="form-row">
                <div className="form-group col-md-16">
                    <label htmlFor="inputState">{label}</label>
                    <select
                        id="inputState"
                        className="form-control"
                        onChange={(e) => setValue(e)}
                        value={getValue()}
                        required={true}
                    >
                        <option value="" selected>Choose...</option>
                        {ls.map((row, index) => {
                            return (
                                <option key={index} value={row.field}>{row.desc}</option>
                            )
                        })}

                    </select>
                </div>
            </div>
        )
    }

    convertDateFormat_YYYYMMDD = (datesIn) => {
        let myDate = moment(datesIn).format("YYYY-MM-DD");
        return myDate;
    }

    submitSearchDashboard = async () => {
        this.setState({
            Data: null,
            AllAttendanceRange: [],
        })

        if (!window.confirm("Are you sure that you want to search for \nStart Date: " + this.state.startDate.toDateString() + "\nEnd Date: " + this.state.endDate.toDateString())) {
            return
        }
        let state = this.state;
        let _this = this;

        let hub = {
            Org: state.organisation,
            RequestType: "agent",// Agent, client, service
            StartDate: this.convertDateFormat_YYYYMMDD(state.startDate),
            EndDate: this.convertDateFormat_YYYYMMDD(state.endDate),
        };


        console.log("Submit submitSearchDashboard > ", hub);

        let backend = eConfig.mainBackend;
        let endpoint = "/api/dashboard/daily";


        await PostToBackend(hub, backend, endpoint, async function (dataIn, err) {
            console.log("Submit submitSearchDashboard response > ", dataIn);
            let inData = [];
            if (typeof dataIn.RESULT !== "undefined") {
                let tmp = _this.state;
                inData = dataIn.RESULT;
                _this.setState(tmp);
            }

            await _this.findCurrentMonthAttendance(hub.StartDate, hub.EndDate, hub.Org, inData);
        });

    }

    removeEntryAttendanceTask = async (org, agent, date, task, id, client) => {
        let _this = this;

        if (!window.confirm("Are sure that you want to delete:\nOrg: " + org + "\nAgent:" + agent + "\nDate:" + date + "\nTask:" + task)) {
            return
        }


        let hub = {};
        hub.username = agent;
        hub.date = date;
        hub.org = org;
        hub.id = id;
        hub.task = task;
        hub.client = client;
        let backend = eConfig.mainBackend;
        let endpoint = "/api/attendance/remove/entry-task";

        console.log("removeEntryAttendanceTask send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("removeEntryAttendanceTask response > ", dataIn);
            let ls = [];
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                _this.closeShowDetail();
                _this.submitSearchDashboard();
            }
        });
    }
    removeEntryAttendance = async (org, agent, date) => {
        let _this = this;

        if (!window.confirm("Are sure that you want to delete:\nOrg: " + org + "\nAgent:" + agent + "\nDate:" + date)) {
            return
        }


        let hub = {};
        hub.username = agent;
        hub.date = date;
        hub.org = org;
        let backend = eConfig.mainBackend;
        let endpoint = "/api/attendance/remove/entry";

        console.log("removeEntryAttendance send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("removeEntryAttendance response > ", dataIn);
            let ls = [];
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                _this.submitSearchDashboard();
            }
        });
    }
    findCurrentMonthAttendance = async (startDate, endDate, org, inData) => {
        let _this = this;

        let hub = {};
        hub.StartDate = startDate;
        hub.EndDate = endDate;
        hub.org = org;
        let backend = eConfig.mainBackend;
        let endpoint = "/api/attendance/get/period";

        console.log("findCurrentMonthAttendance send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("findCurrentMonthAttendance response > ", dataIn);
            let ls = [];
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;


                for (let i in data) {
                    const row = data[i];

                    ls.push({
                        org: row.Org,
                        id: row.Id,
                        date: row.WorkDate,
                        task: row.Task,
                        start: row.StartTime,
                        end: row.EndTime,
                        duration: row.Hours,
                        billable: row.BillableHours,
                        comment: row.Comment,
                        client: row.Client,
                        username: row.WorkerUsername,
                    })
                }


            }

            let tmp = _this.state;
            tmp.Data = inData;
            tmp.AllAttendanceRange = ls;
            _this.setState(tmp);
        });
    }
    handleInput = (e, key) => {
        let tmp = this.state;
        tmp[key] = e.target.value;
        this.setState(tmp);
    }
    getValue = (key) => {
        if (typeof this.state[key] !== "undefined") {
            return this.state[key]
        }
        return "";
    }
    closeShowDetail = () => {
        this.setState({
            showDetail: false,
            DataTasks: [],
        });
    }
    showDetail = (username, date) => {

        let ls = [];
        for (let i in this.state.AllAttendanceRange) {
            const row = this.state.AllAttendanceRange[i];

            if (row.username === username && row.date === date) {
                console.log("::::-> ", row.username, " = ", username, " > ", (row.username === username));
                console.log("::::->D ", row.date, " = ", date, " > ", (row.date === date));
                ls.push(row)
            }
        }

        this.setState({
            showDetail: true,
            DataTasks: ls,
        });
    }
    renderShowDetail() {

        if (!this.state.showDetail) {
            return null
        }

        const actions = [
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.closeShowDetail}
            />,
        ];

        let ls = this.state.DataTasks;

        console.log("^^^^^^^^-> ", ls);

        return (
            <MuiThemeProvider>
                <Dialog
                    title={"Activities Detail Date selected"}
                    actions={actions}
                    modal={false}
                    open={this.state.showDetail}
                    onRequestClose={this.closeShowDetail}
                    autoScrollBodyContent={true}
                >

                    <br/>

                    <div className="col-lg-12">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Task</th>
                                <th>Hours</th>
                                <th>Client</th>
                                <th>Comment</th>
                                <th>Is-Billable?</th>
                                <th>#</th>
                            </tr>
                            </thead>
                            <tbody>
                            {ls.map((row, i) => {
                                return (
                                    <tr key={"i" + i}>
                                        <td>{row.task}</td>
                                        <td>{row.duration}</td>
                                        <td>{row.client}</td>
                                        <td>{row.comment}</td>
                                        <td>{row.billable}</td>
                                        <td>
                                            <a onClick={() => this.removeEntryAttendanceTask(row.org, row.username, row.date, row.task, row.id, row.client)}>Remove
                                                Task</a>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>


                </Dialog>

            </MuiThemeProvider>


        )

    }
    showLeave = (username, date) => {


        this.setState({
            showLeave: true,
            leaveUsername: username,
            leaveDate: date,
            leaveData: this.state.Data.Leaves
        });
    }
    closeShowLeave = () => {
        this.setState({
            showLeave: false,
            leaveUsername: "",
            leaveDate: "",
            leaveData: {}
        });
    }
    clcMultipleHourByRate=(num1,rateIn)=>{
        rateIn = parseFloat(rateIn);
        let decNum1 = (num1 % 1).toFixed(2).substring(2);
        let hourNum1 = this.convertEntryToFLoat(num1);// .toFixed(0);
        let totalMinuteNun1 = (parseInt(hourNum1) * 60) + parseInt(decNum1);

        let rate=0;
        if(rateIn !=0){
            rate = rateIn / 60;
        }

        let total = 0;
        if(totalMinuteNun1 !=0 && rate !=0){
            total = totalMinuteNun1 * rate;
        }

        return total.toFixed(0)
    }

    renderDashboardReportTaskDetailClient = () => {
        let state = this.state;
        if (state.Data === null) {
            return (<table id={"able-to-xls"}></table>)
        }

        let ls = [];
        let agents = [];
        const data = state.Data.DashDailyAgent;

        for (let i in data) {
            const row = data[i];
            //ls.push(row);
            agents.push(i);
        }

        let allAgents = [];
        for (let i in this.state.Employees) {
            const row = this.state.Employees[i];
            allAgents.push({
                username: i,
                name: row.name + " " + row.surname
            })
        }

        const dataCLient = state.Data.DashDailyClient;
        let clients=[];
        for (let i in dataCLient) {
            const row = dataCLient[i];
            //ls.push(row);
            clients.push(i);
        }
        let totalHours=0;
        let totalMoney=0;
        return (
            <div>
                <div className={"alert alert-danger"}>
                    Agent report >
                    From: <b>{state.startDate.toDateString()}</b> TO: <b>{state.endDate.toDateString()}</b>


                </div>

                <div className="form-row">
                    <div className="form-group col-md-16 col-lg-4">
                        <label htmlFor="inputState">View By Client</label>
                        <select
                            className="form-control"
                            onChange={(e) => this.setState({
                                clientDetailViewType: e.target.value
                            })}
                            value={this.state.clientDetailViewType}
                        >
                            <option value={"all"}>All - Client</option>
                            {clients.map((ag, xx) => {
                                return (
                                    <option value={ag}>{ag}</option>
                                )
                            })}

                        </select>
                    </div>
                </div>

                <table className={"table"} id="table-to-xls-taskDetailClient">
                    <thead>

                    <tr>
                        <th>Employee</th>
                        <th>Date</th>
                        <th>Hours</th>
                        <th>Client</th>
                        <th>Task</th>
                        <th style={{textAlign:"right"}}>Rate</th>
                        <th style={{textAlign:"right"}}>Total</th>
                        <th>Comment</th>
                    </tr>
                    </thead>

                    {agents.map((agent, indexAgent) => {


                            let innerData = [];
                            for (let i in this.state.AllAttendanceRange) {
                                const row = this.state.AllAttendanceRange[i];
                                console.log("%^^^^^^-> ",row);
                                if(row.username ===agent){
                                    if (this.state.clientDetailViewType === "all") {
                                        innerData.push(row)
                                    }else{
                                        if (row.client === this.state.clientDetailViewType) {
                                            innerData.push(row)
                                        }
                                    }
                                }


                            }

                            const emp = state.Employees[agent];
                            const rate = state.Rates[emp.role];
                            const fullname = emp.name + " " + emp.surname;
                            const phone = emp.phone;




                            return (
                                <tbody>
                                {/*<tr>
                                    <td rowspan={innerData.length + 2}>
                                        Name:<b>{fullname}</b><br/>
                                        Phone:{phone}<br/>
                                        Role:{emp.role}<br/>
                                        Rate Worker: R{emp.costworker}<br/>
                                        Rate Client: R{emp.costclient}<br/>
                                    </td>
                                </tr>*/}
                                {innerData.map((row, index) => {

                                    let totCost  = this.clcMultipleHourByRate(parseFloat(row.duration),parseFloat(emp.costclient))
                                    totalHours = this.calculateSummerOfHour(totalHours,row.duration);
                                    totalMoney = parseFloat(totalMoney) + parseFloat(totCost);
                                    return (
                                        <tr>
                                            <td><b>{fullname+" -- "+row.username}</b></td>
                                            <td>{row.date}</td>
                                            <td>{row.duration}</td>
                                            <td>{row.client}</td>
                                            <td>{row.task}</td>
                                            <td style={{textAlign:"right"}}>{emp.costclient}</td>
                                            <td style={{textAlign:"right"}}>{totCost}</td>
                                            <td>{row.comment}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            )

                        }
                    )}

                    <tfoot>

                    <tr>
                        <th style={{color:"red",fontSize:18}}>Total</th>
                        <th>&nbsp;</th>
                        <th style={{color:"red",fontSize:18}}>{totalHours}</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th style={{textAlign:"right"}}>&nbsp;</th>
                        <th style={{textAlign:"right",color:"red",fontSize:18}}>{totalMoney}</th>
                        <th>&nbsp;</th>
                    </tr>
                    </tfoot>


                </table>

                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls-taskDetailClient"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"
                />


            </div>
        )

    }
    renderDashboardReportTaskDetailEmp = () => {
        let state = this.state;
        if (state.Data === null) {
            return (<table id={"able-to-xls"}></table>)
        }

        let ls = [];
        let agents = [];
        const data = state.Data.DashDailyAgent;

        for (let i in data) {
            const row = data[i];
            //ls.push(row);
            agents.push(i);
        }

        let allAgents = [];
        for (let i in this.state.Employees) {
            const row = this.state.Employees[i];
            allAgents.push({
                username: i,
                name: row.name + " " + row.surname
            })
        }

        return (
            <div>
                <div className={"alert alert-danger"}>
                    Agent report >
                    From: <b>{state.startDate.toDateString()}</b> TO: <b>{state.endDate.toDateString()}</b>


                </div>

                <div className="form-row">
                    <div className="form-group col-md-16 col-lg-4">
                        <label htmlFor="inputState">View By Agent</label>
                        <select
                            className="form-control"
                            onChange={(e) => this.setState({
                                agentDetailViewType: e.target.value
                            })}
                            value={this.state.agentDetailViewType}
                        >
                            <option value={"all"}>All - Agent</option>
                            {allAgents.map((ag, xx) => {
                                return (
                                    <option value={ag.username}>{ag.name}</option>
                                )
                            })}

                        </select>
                    </div>
                </div>

                <table className={"table"} id="table-to-xls-taskDetail">
                    <thead>

                    <tr>
                        <th>Employee</th>
                        <th>Date</th>
                        <th>Hours</th>
                        <th>Client</th>
                        <th>Task</th>
                        <th>Comment</th>

                    </tr>
                    </thead>

                    {agents.map((agent, indexAgent) => {

                            if (this.state.agentDetailViewType !== "all") {
                                if (this.state.agentDetailViewType !== agent) {
                                    return null
                                }
                            }

                            let innerData = [];
                            for (let i in this.state.AllAttendanceRange) {
                                const row = this.state.AllAttendanceRange[i];

                                if (row.username === agent) {
                                    innerData.push(row)
                                }
                            }

                            const emp = state.Employees[agent];
                            const rate = state.Rates[emp.role];
                            const fullname = emp.name + " " + emp.surname;
                            const phone = emp.phone;


                            return (
                                <tbody>
                                {/*<tr>
                                    <td rowspan={innerData.length + 2}>
                                        Name:<b>{fullname}</b><br/>
                                        Phone:{phone}<br/>
                                        Role:{emp.role}<br/>
                                        Rate Worker: R{emp.costworker}<br/>
                                        Rate Client: R{emp.costclient}<br/>
                                    </td>
                                </tr>*/}
                                {innerData.map((row, index) => {
                                    console.log("%^^^^^^-> ",row);
                                    return (
                                        <tr>
                                            <td><b>{fullname}</b></td>
                                            <td>{row.date}</td>
                                            <td>{row.duration}</td>
                                            <td>{row.client}</td>
                                            <td>{row.task}</td>
                                            <td>{row.comment}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            )

                        }
                    )}


                </table>

                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls-taskDetail"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"
                />


            </div>
        )

    }
    renderDashboardReportAgent = () => {
        let state = this.state;
        if (state.Data === null) {
            return (<table id={"able-to-xls"}></table>)
        }

        let ls = [];
        let agents = [];
        const data = state.Data.DashDailyAgent;

        for (let i in data) {
            const row = data[i];
            //ls.push(row);
            agents.push(i);
        }

        const clcVariantX = (budge, works) => {
            let x = parseFloat(budge);
            let y = parseFloat(works);
            let compare = y - x;
            return compare.toFixed(2);
        }

        const clcVariant = (num1, num2) => {
            let decNum1 = (num1 % 1).toFixed(2).substring(2);
            let decNum2 = (num2 % 1).toFixed(2).substring(2);


            let hourNum1 = this.convertEntryToFLoat(num1);// .toFixed(0);
            let hourNum2 = this.convertEntryToFLoat(num2);// .toFixed(0);

            let totalMinuteNun1 = (parseInt(hourNum1) * 60) + parseInt(decNum1);
            let totalMinuteNun2 = (parseInt(hourNum2) * 60) + parseInt(decNum2);

            let data = totalMinuteNun1 - totalMinuteNun2;
            if(num2 >num1){
                data =   totalMinuteNun2 - totalMinuteNun1;
            }
            let minutes = data % 60;
            let hours = (data - minutes) / 60;
            let msg = hours + "." + minutes;
            let total = parseFloat(msg).toFixed(2);

            let result = "";
            if(num1 > num2){
                result = "-"+total;
                total  = parseFloat(result).toFixed(2);
            }
            return total
        }

        let allAgents = [];
        for (let i in this.state.Employees) {
            const row = this.state.Employees[i];
            allAgents.push({
                username: i,
                name: row.name + " " + row.surname
            })
        }

        return (
            <div>
                <div className={"alert alert-danger"}>
                    Agent report >
                    From: <b>{state.startDate.toDateString()}</b> TO: <b>{state.endDate.toDateString()}</b>


                </div>

                <div className="form-row">
                    <div className="form-group col-md-16 col-lg-4">
                        <label htmlFor="inputState">View By Agent</label>
                        <select
                            className="form-control"
                            onChange={(e) => this.setState({
                                agentDetailViewType: e.target.value
                            })}
                            value={this.state.agentDetailViewType}
                        >
                            <option value={"all"}>All - Agent</option>
                            {allAgents.map((ag, xx) => {
                                return (
                                    <option value={ag.username}>{ag.name}</option>
                                )
                            })}

                        </select>
                    </div>
                </div>

                <table className={"table"} id="table-to-xls-agentSummary1">
                    <thead>

                    <tr>
                        <th>Employee</th>
                        <th>Date</th>
                        <th style={{textAlign:"right"}}>Hours</th>
                        <th style={{textAlign:"right"}}>H-Bill</th>
                        <th style={{textAlign:"right"}}>H-NoBill</th>
                        <th style={{textAlign:"right"}}>H-Extra</th>

                        <th style={{textAlign:"right"}}>Cost Client</th>
                        <th style={{textAlign: "center"}}>Budget</th>
                        <th>Variance</th>
                        <th>Leave</th>
                        <th>#</th>

                    </tr>
                    </thead>

                    {agents.map((agent, indexAgent) => {

                        if (this.state.agentDetailViewType !== "all") {
                            if (this.state.agentDetailViewType !== agent) {
                                return null
                            }
                        }

                        let innerData = [];
                        const myData = data[agent];

                        for (let date in myData) {
                            let row = myData[date];
                            console.log("&&&&&&&&---> ", row);
                            innerData.push({
                                agent: agent,
                                date: date,
                                hours: row.Hours,
                                bill: FloatMe(row.BillableHours),
                                nobill: FloatMe(row.NoBillableHours),
                                extra: FloatMe(row.ExtraHours),
                                costAgent: FloatMe(row.CostAgent),
                                costCompany: FloatMe(row.CostCompany),
                                costClient: FloatMe(row.CostClient),
                                rateWorker: row.RateWorker,
                                rateCompany: row.RateCompany,
                                rateClient: row.RateClient
                            })
                        }

                        let totHours = 0;
                        let totBill = 0;
                        let totNoBill = 0;
                        let totExtra = 0;
                        let totalCostClient = 0;
                        let toatlCostWorker = 0;

                        const emp = state.Employees[agent];
                        const rate = state.Rates[emp.role];
                        const fullname = emp.name + " " + emp.surname;
                        const phone = emp.phone;
                        return (
                            <tbody>
                            <tr>
                                <td rowspan={innerData.length + 2}>
                                    Name:<b>{fullname}</b><br/>
                                    Phone:{phone}<br/>
                                    Role:{emp.role}<br/>
                                    Rate Worker: R{emp.costworker}<br/>
                                    Rate Client: R{emp.costclient}<br/>
                                </td>
                            </tr>
                            {innerData.map((row, index) => {
                                totHours = this.calculateSummerOfHour(totHours, parseFloat(row.hours));
                                totBill = this.calculateSummerOfHour(totBill, parseFloat(row.bill));
                                totNoBill = this.calculateSummerOfHour(totNoBill, parseFloat(row.nobill));
                                totExtra = this.calculateSummerOfHour(totExtra, parseFloat(row.extra));
                                let totCostClient = this.clcMultipleHourByRate(parseFloat(row.hours) , parseFloat(emp.costclient));
                                totalCostClient = totalCostClient + parseFloat(totCostClient);
                                //toatlCostWorker = toatlCostWorker + parseFloat(row.rateWorker);

                                console.log("EMployee > ", emp)
                                const variant = clcVariant(emp.budgethour, row.hours);

                                let haveLeave = "----";
                                const leaves = this.state.Data.Leaves;
                                if (typeof leaves[agent] !== "undefined") {
                                    if (typeof leaves[agent][row.date] !== "undefined") {
                                        haveLeave = leaves[agent][row.date]
                                    }
                                }

                                return (
                                    <tr key={index}>
                                        <td><a style={{cursor: "pointer", color: "blue"}}
                                               onClick={() => this.showDetail(emp.email, row.date)}> {row.date}</a></td>
                                        <td style={{textAlign:"right"}}>{FloatMe(row.hours)}</td>
                                        <td style={{textAlign:"right"}}>{row.bill}</td>
                                        <td style={{textAlign:"right"}}>{row.nobill}</td>
                                        <td style={{textAlign:"right"}}>{row.extra}</td>

                                        <td style={{textAlign: "right"}}>R{FloatMeZero(totCostClient)}</td>
                                        <td style={{textAlign: "center"}}>{emp.budgethour}</td>
                                        {variant < 0 ?
                                            <td style={{color: "red"}}>{variant}</td> :
                                            <td style={{color: "green"}}>{variant}</td>
                                        }
                                        <td style={{textAlign: "center"}}>{haveLeave}</td>
                                        <td><a style={{color: "blue"}}
                                               onClick={() => this.removeEntryAttendance(emp.org, emp.email, row.date)}>Remove</a>
                                        </td>
                                    </tr>
                                )
                            })}

                            <tr style={{color: "black", fontSize: 18, fontWeight: "bold"}}>
                                <td ><b>TOTAL</b></td>
                                <td style={{textAlign:"right"}}>{FloatMe(totHours)}</td>
                                <td style={{textAlign:"right"}}>{FloatMe(totBill)}</td>
                                <td style={{textAlign:"right"}}>{FloatMe(totNoBill)}</td>
                                <td style={{textAlign:"right"}}>{FloatMe(totExtra)}</td>
                                <td style={{textAlign:"right"}}>R{FloatMeZero(totalCostClient)}</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                            </tbody>
                        )
                    })}

                </table>

                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls-agentSummary1"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"
                />
            </div>
        )
    }
    renderDashboardReportClient = () => {
        let state = this.state;
        if (state.Data === null) {
            return null
        }

        let ls = [];
        let agents = [];
        const data = state.Data.DashDailyClient;

        for (let i in data) {
            const row = data[i];
            //ls.push(row);
            agents.push(i);
        }

        const arroundMinuteHour = (num1) => {
            let decNum1 = (num1 % 1).toFixed(2).substring(2);


            let hourNum1 = this.convertEntryToFLoat(num1);// .toFixed(0);


            let totalMinuteNun1 = (parseInt(hourNum1) * 60) + parseInt(decNum1);


            let data = totalMinuteNun1;
            let minutes = data % 60;
            let hours = (data - minutes) / 60;
            let msg = hours + "." + minutes;
            let total = parseFloat(msg).toFixed(2);
            return total
        }


        return (
            <div className={"container"}>
                <p className={"alert alert-danger"}>
                    Client report >
                    From: <b>{state.startDate.toDateString()}</b> TO: <b>{state.endDate.toDateString()}</b>
                </p>

                <div className="form-row">
                    <div className="form-group col-md-16 col-lg-4">
                        <label htmlFor="inputState">View By Client</label>
                        <select
                            className="form-control"
                            onChange={(e) => this.setState({
                                clientDetailViewType: e.target.value
                            })}
                            value={this.state.clientDetailViewType}
                        >
                            <option value={"all"}>All - Client</option>
                            {agents.map((ag, xx) => {
                                return (
                                    <option value={ag}>{ag}</option>
                                )
                            })}

                        </select>
                    </div>
                </div>


                <table className={"table"} id="table-to-xls-agentSummary">
                    <thead>

                    <tr>
                        <th>Client</th>
                        <th>Date</th>
                        <th>Hours</th>
                        <th>H-Bill</th>
                        <th>H-NoBill</th>
                        <th>H-Extra</th>


                    </tr>
                    </thead>

                    {agents.map((agent, indexAgent) => {
                        if (this.state.clientDetailViewType !== "all") {
                            if (this.state.clientDetailViewType !== agent) {
                                return null
                            }
                        }
                        let innerData = [];
                        const myData = data[agent];
                        for (let date in myData) {
                            let row = myData[date];
                            console.log("&&&&&&&&---> ", row);
                            innerData.push({
                                agent: agent,
                                date: date,
                                hours: parseFloat(row.Hours).toFixed(2),
                                bill: parseFloat(row.BillableHours).toFixed(2),
                                nobill: parseFloat(row.NoBillableHours).toFixed(2),
                                extra: FloatMe(row.ExtraHours),
                                costAgent: FloatMe(row.CostAgent),
                                costCompany: FloatMe(row.CostCompany),
                                costClient: FloatMe(row.CostClient),
                                rateWorker: row.RateWorker,
                                rateCompany: row.RateCompany,
                                rateClient: row.RateClient
                            })
                        }

                        let totHours = 0;
                        let totBill = 0;
                        let totNoBill = 0;
                        let totExtra = 0;
                        let totalCostClient = 0;
                        let toatlCostWorker = 0;

                        return (
                            <tbody>
                            <tr>
                                <td rowspan={innerData.length + 2}>
                                    Name:<b>{agent}</b><br/>
                                </td>
                            </tr>
                            {innerData.map((row, index) => {
                                row.hours = arroundMinuteHour(row.hours);
                                row.bill = arroundMinuteHour(row.bill);
                                row.nobill =this.calculateMinusOfHour(row.hours,row.bill) ; //arroundMinuteHour(row.nobill);
                                row.extra = arroundMinuteHour(row.extra);

                                totHours = this.calculateSummerOfHour(totHours, parseFloat(row.hours));
                                totBill = this.calculateSummerOfHour(totBill, parseFloat(row.bill));
                                totNoBill = this.calculateSummerOfHour(totNoBill, parseFloat(row.nobill));
                                totExtra = this.calculateSummerOfHour(totExtra, parseFloat(row.extra));
                                totalCostClient = totalCostClient + parseFloat(row.costClient);
                                toatlCostWorker = toatlCostWorker + parseFloat(row.rateWorker);

                                return (
                                    <tr key={index}>
                                        <td>{row.date}</td>
                                        <td>{row.hours}</td>
                                        <td>{row.bill}</td>
                                        <td>{row.nobill}</td>
                                        <td>{row.extra}</td>

                                    </tr>
                                )
                            })}

                            <tr style={{color: "red", fontSize: 18, fontWeight: "bold"}}>
                                <td><b>TOTAL</b></td>
                                <td>{FloatMe(totHours)}</td>
                                <td>{FloatMe(totBill)}</td>
                                <td>{FloatMe(totNoBill)}</td>
                                <td>{FloatMe(totExtra)}</td>
                            </tr>
                            </tbody>
                        )
                    })}

                </table>

                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls-agentSummary"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"
                />

            </div>
        )
    }
    renderDashboardReportSummaryAgent = () => {
        let state = this.state;
        if (state.Data === null) {
            return null
        }

        const emps = state.Employees;
        let ls = [];
        const data = state.Data.DashboardSummaryAgent;

        for (let i in data) {
            const row = data[i];
            row.emp = i;
            ls.push(row);
        }

        const alignRight = {textAlign: "right"};
        return (
            <div className={"container"}>
                <table className={"table"}>
                    <thead>
                    <tr>
                        <th style={alignRight}>Employee</th>
                        <th style={alignRight}>Hours</th>
                        <th style={alignRight}>H.Bill</th>
                        <th style={alignRight}>H.NoBill</th>
                        <th style={alignRight}>H.Extra</th>
                        {/*<th style={alignRight}>Cost To Company</th>*/}
                        <th style={alignRight}>Cost To Client</th>
                        <th style={alignRight}>Leave</th>
                        {/*<th style={alignRight}>Company Rate</th>*/}
                        <th style={alignRight}>Client Rate</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ls.map((row, z) => {
                        if (typeof emps[row.emp] === "undefined") {
                            return null
                        }
                        const agentName = emps[row.emp].name + " " + emps[row.emp].surname;
                        console.log("emps ):(--> ", row);
                        let hasLeave = "no";
                        if (row.HasLeave) {
                            hasLeave = "yes";
                        }

                        const myEmp = emps[row.emp];
                        const totCostClient =this.clcMultipleHourByRate( parseFloat(myEmp.costclient) , parseFloat(row.Hours));
                        return (
                            <tr>
                                <td style={alignRight}>{agentName}</td>
                                <td style={alignRight}>{row.Hours.toFixed(2)}</td>
                                <td style={alignRight}>{row.BillableHours.toFixed(2)}</td>
                                <td style={alignRight}>{row.NoBillableHours.toFixed(2)}</td>
                                <td style={alignRight}>{row.ExtraHours.toFixed(2)}</td>
                                {/*<td style={alignRight}>{row.CostAgent.toFixed(2)}</td>*/}
                                <td style={alignRight}>{totCostClient}</td>
                                <td style={alignRight}>{hasLeave}</td>
                                {/*<td style={alignRight}>{myEmp.costcompany.toFixed(0)}</td>*/}
                                <td style={alignRight}>{myEmp.costclient.toFixed(0)}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>

                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls-ClientVariance"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"
                />

            </div>
        )


    }
    renderDashboardReportSummaryEmpClientPerHour = () => {
        let state = this.state;
        if (state.Data === null) {
            return null
        }

        const emps = state.Employees;
        let ls = [];
        let clients = state.Data.Clients;
        if (typeof clients === "undefined") {
            clients = [];
        }
        const data = state.Data.DashboardSummaryClient;

        for (let i in data) {
            const row = data[i];
            row.emp = i;
            ls.push(row);
        }

        let totalBudget = 0;
        let totalActual = 0;
        let totalVariance = 0;

        let arrTotalClient = [];

        const styleTotal = {color: "red", textAlign: "center", fontWeight: "bold", fontSize: 18};


        return (
            <div className="table-wrapper-scroll-y my-custom-scrollbar">

                <table className="table-bordered table-striped mb-0" id="table-to-xls-clientPerHour">
                    <thead>
                    <tr>
                        <th>Worker</th>
                        <th>Budget Hours</th>
                        <th>Actual Hours</th>
                        <th>Variance</th>
                        {clients.map((cl, x) => {
                            return (
                                <th style={{color: "blue"}} key={"clh" + x}>{cl.Name}</th>
                            )
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {ls.map((row, z) => {
                        console.log("Z#######---> ", row.emp, " > ", emps);
                        if (typeof emps[row.emp] === "undefined") {
                            return null
                        }
                        const clData = row.ClientHours;
                        const agentName = emps[row.emp].name + " " + emps[row.emp].surname;

                        totalBudget = this.calculateSummerOfHour(totalBudget, parseFloat(row.BudgetHour));
                        totalActual = this.calculateSummerOfHour(totalActual, parseFloat(row.Hours));
                        totalVariance = this.calculateSummerOfHour(totalVariance, parseFloat(row.VarianceHour));


                        return (
                            <tr>
                                <th>{agentName}</th>
                                <td>{row.BudgetHour.toFixed(0)}</td>
                                <td>{row.Hours.toFixed(2)}</td>
                                <td>{row.VarianceHour.toFixed(0)}</td>
                                {clients.map((cl, x) => {

                                    const o = clData[cl.Name];
                                    let cost = 0;
                                    if (typeof clData[cl.Name] !== "undefined") {
                                        cost = clData[cl.Name];
                                        cost = cost.toFixed(2);
                                    }

                                    let totClient = 0;
                                    if (typeof arrTotalClient[x] !== "undefined") {
                                        totClient = arrTotalClient[x];
                                    }
                                    totClient = parseFloat(totClient) + parseFloat(cost);
                                    arrTotalClient[x] = totClient;

                                    return (
                                        <td style={{color: "blue", textAlign: "center"}}>{cost}</td>
                                    )
                                })
                                }
                            </tr>
                        )
                    })}
                    </tbody>
                    <tfoot>
                    <tr>
                        <th>&nbsp;</th>
                        <td style={styleTotal}>{parseFloat(totalBudget).toFixed(0)}</td>
                        <td style={styleTotal}>{parseFloat(totalActual).toFixed(0)}</td>
                        <td style={styleTotal}>{parseFloat(totalVariance).toFixed(0)}</td>
                        {arrTotalClient.map((cost, x) => {
                            let costMe = parseFloat(cost);
                            return (
                                <td style={styleTotal}>{costMe.toFixed(0)}</td>
                            )
                        })}
                    </tr>
                    </tfoot>
                </table>

                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls-clientPerHour"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"
                />

            </div>

        )


    }
    renderDashboardReportSummaryEmpClientPerValue = () => {
        let state = this.state;
        if (state.Data === null) {
            return null
        }

        const emps = state.Employees;
        let ls = [];
        let clients = state.Data.Clients;
        const data = state.Data.DashboardSummaryClient;

        for (let i in data) {
            const row = data[i];
            row.emp = i;
            ls.push(row);
        }

        let arrTotalClient = [];
        let totalBudget = 0;
        let totalActual = 0;
        let totalVariance = 0;
        const styleTotal = {color: "red", textAlign: "right", fontWeight: "bold", fontSize: 18};


        return (
            <div className="table-wrapper-scroll-y my-custom-scrollbar">

                <table className="table-bordered table-striped mb-0" id="table-to-xls-clientPerValue">

                    <thead>
                    <tr>
                        <th>Emp.</th>
                        <th>Budget billable</th>
                        <th>Actual billable</th>
                        <th>Variance</th>
                        {clients.map((cl, x) => {
                            return (
                                <th style={{color: "blue"}} key={"clh" + x}>{cl.Name}</th>
                            )
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {ls.map((row, z) => {

                        if (typeof emps[row.emp] === "undefined") {
                            return null
                        }

                        const clData = row.ClientHours;
                        console.log("EMP:> ", emps[row.emp]);
                        const agentName = emps[row.emp].name + " " + emps[row.emp].surname;

                        const rateCient = emps[row.emp].costclient;
                        const costBudget = parseFloat(row.BudgetHour) * parseFloat(rateCient);
                        const costActual = parseFloat(row.Hours) * parseFloat(rateCient);
                        const costVariance = parseFloat(row.VarianceHour) * parseFloat(rateCient);

                        totalBudget = totalBudget + costBudget;
                        totalActual = totalActual + costActual;
                        totalVariance = totalVariance + costVariance;

                        return (
                            <tr>
                                <th>{agentName}</th>
                                <td>{costBudget.toFixed(0)}</td>
                                <td>{costActual.toFixed(0)}</td>
                                <td>{costVariance.toFixed(0)}</td>
                                {clients.map((cl, x) => {
                                    const o = clData[cl.Name];
                                    let cost = 0;
                                    if (typeof clData[cl.Name] !== "undefined") {
                                        cost = clData[cl.Name];
                                        cost = parseFloat(cost) * parseFloat(rateCient);
                                        cost = cost.toFixed(0);
                                    }

                                    let totClient = 0;
                                    if (typeof arrTotalClient[x] !== "undefined") {
                                        totClient = arrTotalClient[x];
                                    }
                                    totClient = parseFloat(totClient) + parseFloat(cost);
                                    arrTotalClient[x] = totClient;

                                    return (
                                        <td style={{color: "blue", textAlign: "right"}}>{cost}</td>
                                    )
                                })
                                }
                            </tr>
                        )
                    })}
                    </tbody>
                    <tfoot>
                    <tr>
                        <th>&nbsp;</th>
                        <td style={styleTotal}>{totalBudget.toFixed(0)}</td>
                        <td style={styleTotal}>{totalActual.toFixed(0)}</td>
                        <td style={styleTotal}>{totalVariance.toFixed(0)}</td>
                        {arrTotalClient.map((cost, x) => {
                            let costMe = parseFloat(cost);
                            return (
                                <td key={x} style={styleTotal}>{costMe.toFixed(0)}</td>
                            )
                        })}
                    </tr>
                    </tfoot>
                </table>

                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls-clientPerValue"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"
                />

            </div>
        )


    }
    renderDashboardReportSummaryEmpClientVariance = () => {
        let state = this.state;
        if (state.Data === null) {
            return null
        }

        const emps = state.Employees;
        let ls = [];
        let clients = state.Data.Clients;
        const data = state.Data.DashboardSummaryClient;

        for (let i in data) {
            const row = data[i];
            row.emp = i;
            ls.push(row);
        }

        let totalBudgetHours = 0, totalActualHours = 0, totalVarianceHours = 0,
            totalBudgetBillable = 0, totalActualBillable = 0, totalVarianceBillable = 0;

        const styleTotal = {
            color: "red",
            fontSize: 17,
            fontWeight: "bold",
            textAlign: "right"
        }

        return (
            <div className={"container"}>
                <table className={"table"} id="table-to-xls-ClientVariance">
                    <thead>
                    <tr>
                        <th>Employee</th>
                        <th>Budget Hours</th>
                        <th>Actual Hours</th>
                        <th>Variance Hours</th>
                        <th>Budget Billable</th>
                        <th>Actual Billable</th>
                        <th>Variance Billable</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ls.map((row, z) => {
                        if (typeof emps[row.emp] === "undefined") {
                            return null
                        }
                        totalBudgetHours = parseFloat(totalBudgetHours) + parseFloat(row.BudgetHour);
                        totalActualHours = parseFloat(totalActualHours) + parseFloat(row.Hours);
                        totalVarianceHours = parseFloat(totalVarianceHours) + parseFloat(row.VarianceHour);

                        totalBudgetBillable = parseFloat(totalBudgetBillable) + parseFloat(row.BudgetCost);
                        totalActualBillable = parseFloat(totalActualBillable) + parseFloat(row.CostCompany);
                        totalVarianceBillable = parseFloat(totalVarianceBillable) + parseFloat(row.VarianceCost);

                        const agentName = emps[row.emp].name + " " + emps[row.emp].surname;
                        return (
                            <tr>
                                <th>{agentName}</th>
                                <td style={{textAlign: "right"}}> {row.BudgetHour.toFixed(2)}</td>
                                <td style={{textAlign: "right"}}>{row.Hours.toFixed(2)}</td>
                                <td style={{textAlign: "right"}}>{row.VarianceHour.toFixed(2)}</td>
                                <td style={{textAlign: "right"}}>R {row.BudgetCost.toFixed(0)}</td>
                                <td style={{textAlign: "right"}}>R {row.CostCompany.toFixed(0)}</td>
                                <td style={{textAlign: "right"}}>R {row.VarianceCost.toFixed(0)}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                    <tfoot>
                    <tr>
                        <th style={styleTotal}>Total:</th>
                        <th style={styleTotal}>{totalBudgetHours.toFixed(2)}</th>
                        <th style={styleTotal}>{totalActualHours.toFixed(2)}</th>
                        <th style={styleTotal}>{totalVarianceHours.toFixed(2)}</th>
                        <th style={styleTotal}>R {totalBudgetBillable.toFixed(0)}</th>
                        <th style={styleTotal}>R {totalActualBillable.toFixed(0)}</th>
                        <th style={styleTotal}>R {totalVarianceBillable.toFixed(0)}</th>
                    </tr>
                    </tfoot>
                </table>

                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls-ClientVariance"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"
                />

            </div>
        )


    }


    render() {
        return (


            <PageContentWallpaper {...this.props} >
                <MuiThemeProvider>

                    <div className="row">
                        <div className="col-xs-12">
                            <h2>Dashboard Date Range Report</h2>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-xs-12">
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label htmlFor="inputState">Start Date</label>
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onChange={this.handleChangeStartDate}
                                        className="form-control"
                                    />
                                </div>

                                <div className="form-group col-md-4">
                                    <label htmlFor="inputState">End Date</label>
                                    <DatePicker
                                        selected={this.state.endDate}
                                        onChange={this.handleChangeEndDate}
                                        className="form-control"
                                    />

                                </div>

                                <div className="form-group col-md-4">
                                    <button
                                        className="form-control pull-right btn btn-default"
                                        onClick={() => this.submitSearchDashboard()}
                                    >Search
                                    </button>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-12">

                            <Tabs>
                                <Tab label="Employee.Detail">
                                    {this.renderDashboardReportAgent()}
                                </Tab>
                                <Tab label="Employee.Summary">
                                    {this.renderDashboardReportSummaryAgent()}
                                </Tab>

                                <Tab label="Client">
                                    {this.renderDashboardReportClient()}
                                </Tab>

                                <Tab label={"Summary/Hour"}>
                                    {this.renderDashboardReportSummaryEmpClientPerHour()}
                                </Tab>
                                <Tab label={"Summary/Value"}>
                                    {this.renderDashboardReportSummaryEmpClientPerValue()}
                                </Tab>

                                <Tab label={"Total.Variance"}>
                                    {this.renderDashboardReportSummaryEmpClientVariance()}
                                </Tab>

                                <Tab label={"Task.Emp"}>
                                    {this.renderDashboardReportTaskDetailEmp()}
                                </Tab>

                                <Tab label={"Task.Client"}>
                                    {this.renderDashboardReportTaskDetailClient()}
                                </Tab>


                            </Tabs>

                        </div>


                    </div>

                    <div>
                        {this.renderShowDetail()}
                    </div>

                </MuiThemeProvider>


            </PageContentWallpaper>


        );
    }
}


const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

const mapStateToProps = state => ({
    addingModel: state.addingModel,
    login: state.login
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AttendanceNewComponent)