
import React from "react";

const Footer=(props)=>{

    return(
        <div className={"myFooterBox"}>
            <p>Powered by Apex Point Ventures  |
                <span className={"footerEmailLink"}>Email: Mgodlwam@apexpointventures.co.za</span> |
                <span className={"footerPhoneLink"}>Phone: + 27 87 313 25634</span> |
            </p>
        </div>
    )
}

export default Footer;